import Vue from 'vue';

Vue.mixin({
    methods: {
        isValidCheckClass(checked, stepBtnClicked = false) {
            if (checked == false && stepBtnClicked) {
                return 'notValid'
            } else {
                return ''
            }
        },
        isValidPhoneClass(phone, stepBtnClicked = false) {
            if (phone.length == 0 && !stepBtnClicked) {
                return ''
            } else if (phone.length == 0) {
                return 'notValid'
            } else if (this.isValidPhone(phone)) {
                return 'valid'
            } else {
                return 'notValid'
            }
        },
        isValidPhoneClassDiscount(phone, stepBtnClicked = false) {
            if (phone.length === 0 && !stepBtnClicked) {
                return ''
            } else if (phone.length === 0) {
                return 'notValid'
            } else if (this.isValidPhone(phone)) {
                return 'valid'
            } else {
                return 'notValid'
            }
        },
        isValidPriceClass(price, stepBtnClicked = false) {
            if (price!= null && price != "") {
                var priceFloat = parseFloat(price)
                if (priceFloat <= 0) {
                    return 'notValid'
                } else {
                    return 'valid'
                }
            } else if (stepBtnClicked) {
                return 'notValid'
            } else {
                return ''
            }
        },
        isValidPriceClassDiscount(price, stepBtnClicked = false, min, max) {
            if(min && price < min || max && price > max){
                return 'notValid'
            }
            return this.isValidPriceClass(price, stepBtnClicked)
        },
        isValidImeiNumberClass(imei, stepBtnClicked = false) {
            if(imei.length == 0 && !stepBtnClicked) return '';
            else if(imei.length == 0 && stepBtnClicked) return 'notValid';
            else{
                var imeiRegex = /^\d{15}$/;
                return imeiRegex.test(imei) && this.isValidImei(imei) ? 'valid' : 'notValid';
            }
        },
        isValidLimitedPriceClass(min, max, price, stepBtnClicked) {
            if (price != "") {
                var priceFloat = parseFloat(price)
                return priceFloat >= min && priceFloat <= max ? 'valid' : 'notValid';
            } else if (stepBtnClicked) {
                return 'notValid'
            } else {
                return ''
            }
        },

        isValidLimitedPriceMultideviceClass(min, max, total, price, stepBtnClicked) {
            if (price != "" && price >= 0) {
                return total >= min && total <= max ? 'valid' : 'notValid';
            } else if (stepBtnClicked || price < 0) {
                return 'notValid'
            } else {
                return ''
            }
        },

        isValidBirthDateClass(date, stepBtnClicked = false) {
            if (date.length == 0 && !stepBtnClicked) {
                return ''
            } else if (date.length == 0) {
                return 'notValid'
            } else if (this.isValidBirthDate(date)) {
                return 'valid'
            } else {
                return 'notValid'
            }
        },
        isValidConstitutionClass(date, stepBtnClicked = false) {
            if (date.length == 0 && !stepBtnClicked) {
                return ''
            } else if (date.length == 0) {
                return 'notValid'
            } else if (this.isValidConstitutionDate(date)) {
                return 'valid'
            } else {
                return 'notValid'
            }
        },
        isValidPurchaseDateClass(landing, date, stepBtnClicked = false) {
            if (date.length == 0 && !stepBtnClicked) {
                return ''
            } else if (date.length == 0) {
                return 'notValid'
            } else if (this.isValidPurchaseDate(landing, date)) {
                return 'valid'
            } else {
                return 'notValid'
            }
        },
        isValidSelectedDeviceClass(listDevices, selectedDevice, incorrectDevice, stepBtnClicked = false) {
            if(listDevices === '' || selectedDevice === '' && !incorrectDevice && !stepBtnClicked){
                return '';
            }else if(!listDevices.includes(selectedDevice)){
                return 'notValid';
            }else if(listDevices.includes(selectedDevice)){
                return 'valid';
            }
        },
        isValidNifNieClass(nif, stepBtnClicked = false, date = '') {
            if (nif.length == 0 && !stepBtnClicked) {
                return ''
            } else if (nif.length == 0) {
                return 'notValid'
            } else if (this.isValidNifNie(nif, date)) {
                return 'valid'
            } else {
                return 'notValid'
            }
        },
        isValidTextClassNotRequired(text, length = 0, stepBtnClicked = false) {
            if (text?.length == 0 && !stepBtnClicked) {
                return ''
            } else if (text?.length == 0 || this.isValidTextField(text, length)) {
                return 'valid'
            } else {
                return 'notValid'
            }
        },
        isValidTextClass(text, length = 0, stepBtnClicked = false) {
            if ((!text || (text && text.length == 0)) && !stepBtnClicked) {
                return ''
            } else if(!text && stepBtnClicked){
                return 'notValid'
            } else if (text && text.length == 0) {
                return 'notValid'
            } else if (this.isValidTextField(text, length)) {
                return 'valid'
            } else {
                return 'notValid'
            }
        },
        isValidNumberClass(text, length = 0, stepBtnClicked = false) {
            if (text.length == 0 && !stepBtnClicked) {
                return ''
            } else if (this.isValidNumberField(text, length)) {
                return 'valid'
            } else {
                return 'notValid'
            }
        },
        isValidEmailClass(email, stepBtnClicked = false) {
            if (email.length == 0 && !stepBtnClicked) {
                return ''
            } else if (email.length == 0) {
                return 'notValid'
            } else if (this.isValidEmail(email)) {
                return 'valid'
            } else {
                return 'notValid'
            }
        },
        isValidCardNumberClass(cardNumber, stepBtnClicked = false) {
            if (cardNumber.length == 0 && !stepBtnClicked) {
                return ''
            } else if (cardNumber.length == 0) {
                return 'notValid'
            } else if (this.isValidCardNumber(cardNumber)) {
                return 'valid'
            } else {
                return 'notValid'
            }
        },
        isValidExpirationDateMonthCardClass(month, stepBtnClicked = false) {
            if (month.length == 0 && !stepBtnClicked) {
                return ''
            } else if (month.length == 0) {
                return 'notValid'
            } else if (this.isValidExpirationDateMonthCard(month)) {
                return 'valid'
            } else {
                return 'notValid'
            }
        },
        isValidExpirationDateYearCardClass(year, stepBtnClicked = false) {
            if (year.length == 0 && !stepBtnClicked) {
                return ''
            } else if (year.length == 0) {
                return 'notValid'
            } else if (this.isValidExpirationDateYearCard(year)) {
                return 'valid'
            } else {
                return 'notValid'
            }
        },
        isValidCVVCardClass(cvv, stepBtnClicked = false) {
            if (cvv.length == 0 && !stepBtnClicked) {
                return ''
            } else if (cvv.length == 0) {
                return 'notValid'
            } else if (this.isValidCVVCard(cvv)) {
                return 'valid'
            } else {
                return 'notValid'
            }
        },
        isValidIBANClass(iban, stepBtnClicked = false) {
            if (iban.length == 0 && !stepBtnClicked) {
                return ''
            } else if (iban.length == 0) {
                return 'notValid'
            } else if (this.isValidIBAN(iban)) {
                return 'valid'
            } else {
                return 'notValid'
            }
        },
        isValidAcceptanceTypeClass(acceptanceType, stepBtnClicked = false) {
            if (acceptanceType == 0 && !stepBtnClicked) {
                return ''
            } else if (acceptanceType == 0) {
                return 'notValid'
            } else {
                return ''
            }
        },

        isValidMoralPersonClass(nif, birth_date, stepBtnClicked = false) {
            if (nif.length == 0 && !stepBtnClicked) {
                return ''
            } else if (nif.length == 0) {
                return 'notValid'
            } else if (this.isValidMoralPerson(nif) && this.isValidConstitutionClass(birth_date, stepBtnClicked) == 'valid') {
                return 'valid'
            } else {
                return 'notValid'
            }
        },
    }
})