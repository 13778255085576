import { getters } from '@/state/store'

export function getProductCategoryId(value, insurance) {
    // Obtenemos el producto asociado
    const product = getters.getProducts().find(i => i.id === insurance.product_id)
    if (!product) { return -1 }

    var productCategory = product.prices != null ? product.prices.filter(function (item) { return item.min_value <= value && item.max_value >= value && item.valid_until === null && item.special_prices === false
    })[0] : -1

    if (typeof productCategory === 'undefined') return -1
    return productCategory.pivot.product_category_id
}