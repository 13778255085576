<template>
    <svg
      :class="`mdi mdi-${icon}`"
      fill="currentColor"
      width="24"
      height="24"
    >
      <path :d="pathData" />
    </svg>
  </template>
  
  <script>
  import * as mdi from '@mdi/js'; // Importa todos los íconos
  
  export default {
    name: 'MdiIcon',
    props: {
      icon: {
        type: String,
        required: true,
      },
    },
    computed: {
      pathData() {
        return mdi[this.icon] || ''; // Devuelve el path del ícono basado en el nombre
      },
    },
  };
  </script>
  
  
  