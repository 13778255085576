import pageRoutes from '../config/constants/routes.js'
import Contracting from '@/pages/Contracting';
import ContractingPalace from '@/pages/ironPalace/ContractingPalace.vue';

export const routes = [
    {
        path: pageRoutes.initial,
        component: () => import(/* webpackChunkName: "MainComponent" */ "../components/MainComponent.vue"),
        children: [
            { name: 'home', path: '', component: () => import(/* webpackChunkName: "MainComponent" */ "../pages/Home.vue")},
            { name: 'contact', path: pageRoutes.contact, component: () => import(/* webpackChunkName: "MainComponent" */ "../pages/Contact.vue")},
            { name: 'map', path: pageRoutes.map, component: () => import(/* webpackChunkName: "MainComponent" */ "../pages/Map.vue") },
        ],
        props: true
    },
    { name: 'discount', path: pageRoutes.discount, component: () => import(/* webpackChunkName: "MainComponent" */ "../pages/DiscountsBox.vue"), props: true},
    {
        path: pageRoutes.initial,
        component: () => import(/* webpackChunkName: "MainCmsComponent" */ "../components/MainCmsComponent.vue"),
        children: [
            { name: 'legal-warning', path: pageRoutes.legalwarning, component: () => import(/* webpackChunkName: "MainCmsComponent" */ "../pages/LegalWarning.vue") },
            { name: 'contracting-conditions', path: pageRoutes.contractingconditions, component: () => import(/* webpackChunkName: "MainCmsComponent" */ "../pages/ContractingConditions.vue") },
            { name: 'privacity', path: pageRoutes.privacity, component: () => import(/* webpackChunkName: "MainCmsComponent" */ "../pages/Privacity.vue") },
            { name: 'cookies', path: pageRoutes.cookies, component: () => import(/* webpackChunkName: "MainCmsComponent" */ "../pages/Cookies.vue") }
        ],
        props: true
    }, 
    {
        path: pageRoutes.order,
        component: () => import(/* webpackChunkName: "MainComponent" */ "../layouts/MainLayout.vue"),
        children: [{
                name: 'order',
                path: '',
                // component: Order,
                component: () => import(/* webpackChunkName: "MainComponent" */ "../pages/Order.vue"),
            },
            {
                path: pageRoutes.orderdiscounts,
                component: () => import(/* webpackChunkName: "MainComponent" */ "../layouts/MainLayout.vue"),
                children: [{
                    name: 'orderdiscounts',
                    path: pageRoutes.orderdiscounts,
                    component: () => import(/* webpackChunkName: "MainComponent" */ "../pages/OrderDiscountsBox.vue"),
                }]
            },
            {
                path: pageRoutes.ordererror,
                component: () => import(/* webpackChunkName: "MainComponent" */ "../layouts/MainLayout.vue"),
                children: [{
                    name: 'ordererror',
                    path: pageRoutes.ordererror,
                    component: () => import(/* webpackChunkName: "MainComponent" */ "../pages/OrderError.vue"),
                }]
            },
            {
                path: pageRoutes.orderok,
                component: () => import(/* webpackChunkName: "MainComponent" */ "../layouts/MainLayout.vue"),
                children: [{
                    name: 'orderok',
                    path: pageRoutes.orderok,
                    component: () => import(/* webpackChunkName: "MainComponent" */ "../pages/OrderOK.vue")
                }]
            }
        ]
    },
    {
        path: pageRoutes.contracting,
        component: Contracting,
        props: route => ({
            landing: route.params.landing,
            product: route.query.u,
            distributor: route.query.distributor,
            tpvOk: route.query.tpv_ok,
            policyNumber: route.query.policy_number
        }),
        beforeEnter:( (to, from, next) =>{
            if(to.fullPath.includes('amp%3B')){
                window.location = window.location.protocol + "//" + window.location.host +to.fullPath.replaceAll('amp%3B','');
            }
            else{
                next();
            }
          }),
    },
    {
        path: pageRoutes.contractingPalace,
        component: ContractingPalace
    },
    {
        name: 'error',
        path: pageRoutes.error,
        component: () => import(/* webpackChunkName: "Error404" */ "../pages/Error.vue")
    },
    {
        path: '*',
        component: () => import(/* webpackChunkName: "Error404" */ "../pages/Error404.vue")
    }
]

export default routes