<template>
  <div class="left form-group mt-2 mb-3">
    <label class="pb-0">{{$t('order.paymentPeriodicity.question_periodicity')}}</label><br />
    <div class="container">
      <div @change="$emit('update', $event.target.value)" class="row">
          <div class="custom-control custom-checkbox mt-1 col-4">
              <input
                  value="1"
                  :checked="periodicity=='1'"
                  type="radio"
                  id="periodicidad_1"
                  name="periodicity"
                  class="custom-control-input"
                  :disabled="!showMonthlyPeriodicityOption"
              />
              <label class="custom-control-label" for="periodicidad_1">{{$t('contractingLanding.month')}} </label>
          </div>
          <div class="custom-control custom-checkbox mt-1 col-4">
              <input
                  value="12"
                  :checked="periodicity=='12'"
                  type="radio"
                  id="periodicidad_12"
                  name="periodicity"
                  class="custom-control-input"
              />
              <label class="custom-control-label" for="periodicidad_12"
                  >{{$t('contractingLanding.year')}}
              </label>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Periodicity",
  props: {
    showMonthlyPeriodicityOption: Boolean,
    periodicity: String,
  },
};
</script>
<style lang="scss" scoped>
    .left{
        text-align: left;
    }
</style>