<template>
    <div class="range-table">
        <table class="table table-striped mt-0">
          <thead>
            <tr>
              <th>MONTO DISPONIBLE</th>
              <th>PAGO ANUAL</th>
              <th>Nº  SINIESTROS</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(rate, index) in rates" :key="index">
              <td>{{rate.name}}</td>
              <td>{{rate.pivot.price}}$</td>
              <td>{{rate.risk_number.risk_number_text}}</td>
            </tr>
          </tbody>
        </table>
    </div>
</template>

<script>

export default {
  name: "RangeTableInfo",
  props: {
      rates: [],
  },
  data() {
    return {
      focused: false,
      currentLanding: "seguromultidispositivo"

    }
  },
  methods: {
  },
};
</script>

<style scoped>
.range-table{
  display: flex;
  min-width: 0;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;
}
.table {
  margin-top: 5%;
  min-height: 0;
}
.checkboxes {
  border-spacing: 0;
  border-collapse: collapse;
  width: 20%;
}
.checkboxes thead {
  border: 2%;
}
.table-stripped {
  width: 80%;
  min-width: 0;
}
th {
  background-color: #EBB248;
  border: 20px solid white;
  font-family: Gotham-Bold;
}
td {
  font-family: Gotham-Light;
}
tr {
  padding: 2%;
}
.checkboxes thead tr th {
  background-color: #ffffff00;
  color: #ffffff00;
  border-top: 0px;
  border-bottom: 0px;
}
.checkboxes td {
  border-top: 1px solid white;
}
.checkbox:checked:before{
  background-color: #EBB248;
}
input[type=checkbox]
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(3); /* IE */
  -moz-transform: scale(3); /* FF */
  -webkit-transform: scale(3); /* Safari and Chrome */
  -o-transform: scale(3); /* Opera */
  transform: scale(3);
  padding: 10px;
}
</style>