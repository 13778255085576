const fixedDomains = {}

if (process.env.VUE_APP_COUNTRY_CODE == 'it') {
    fixedDomains['seguromovil'] = 'tusmartphoneassicurazione.com',
    fixedDomains['seguromultidispositivo'] = 'tumultidispositivoassicurazione.com',
    fixedDomains['seguroportatil'] = 'tulaptopassicurazione.com',
    fixedDomains['insurama'] = 'insurama'
} else if (process.env.VUE_APP_COUNTRY_CODE == 'pt') {
    fixedDomains['seguromovil'] = 'seusegurotelemovel',
    fixedDomains['seguromultidispositivo'] = 'seuseguromulti-dispositivos',
    fixedDomains['seguroportatil'] = 'seusegurolaptop',
    fixedDomains['insurama'] = 'insurama'
} else if (process.env.VUE_APP_COUNTRY_CODE == 'mx') {
    fixedDomains['seguromovil'] = 'segurocelular',
    fixedDomains['seguromultidispositivo'] = 'seguromultidispositivo',
    fixedDomains['seguroportatil'] = 'segurolaptop',
    fixedDomains['insurama'] = 'insurama'
} else {
    fixedDomains['seguromovil'] = 'tusmartphoneassicurazione.com',
    fixedDomains['seguromultidispositivo'] = 'tumultidispositivoassicurazione.com',
    fixedDomains['seguroportatil'] = 'tulaptopassicurazione.com',
    fixedDomains['insurama'] = 'insurama'
}

module.exports = fixedDomains