<template>
  <div class="purchase-info mt-5">
      <div class="mx-auto" style="width: 50%">EL SEGURO MIS DISPOSITIVOS INSURAMA Y GNP SEGUROS HA SIDO ACTIVADO CORRECTAMENTE.</div>
      <h3 class="mt-1 mb-4">EL N° DE PÓLIZA ES: {{ policyNumber }}</h3>
      <div style="display: flex; justify-content: center">
        <div class="border-top mt-2" style="width: 50%; heigth: 3px;"></div>
      </div>
      <div class="mr-auto ml-auto" style="width: 50%">
        Al cliente le llegará a su correo electrónico: 
      </div>
      <div class="mr-auto ml-auto d-flex justify-content-center flex-row" style="width: 80%">
        <h6 class="email-cliente mb-0 mr-1">[{{policyEmail}}] </h6> 
        <h7>
          su usuario y contraseña. Este correo llegará desde la dirección 
        </h7>
        <h6 class="email-cliente ml-1 mb-0">clientes@insurama.mx</h6> 
      </div>
      <h6 class="mt-5 mb-5">RECUÉRDALE AL CLIENTE QUE PARA FINALIZAR SU PROCESO ES NECESARIO:</h6>
      <div class="container">
        <div class="row">
          <div class="col-sm mr-2">
            <div class="steps-info align-items-center d-flex flex-column">
              <img src="../../assets/palacio-hierro/logo-redondo.svg" alt="QR" style="height: 100px;" class="round-logo mb-2">
              <h6 class="align-middle">DESCARGAR LA </h6>
              <h6 class="align-middle">APLICACIÓN INSURAMA MX </h6>
            </div>
          </div>
          <div class="col-sm mr-2">
            <div class="steps-info align-items-center">
              <h6 class="align-middle">DESDE LA APLICACIÓN DEBE VERIFICAR SU EQUIPO</h6>
            </div>
          </div>
          <div class="col-sm">
            <div class="steps-info align-items-center">
              <h6 class="align-middle">DESDE LA APLICACIÓN DEBE FIRMAR SU PÓLIZA </h6>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <b-button class="continue"  v-b-modal.modal-5>AYUDA PARA VERIFICACIÓN</b-button>
      </div>
    <button @click="goToFirstStep" type="button" class="cancel p-2 pl-5 pr-5 label">NUEVA CONTRATACIÓN</button>
    <div class="d-flex justify-content-start">
      <img src="../../assets/palacio-hierro/qr.svg" alt="QR" style="height: 150px;" class="ml-4 mb-3">
      <h5 class="col d-flex align-items-end ml-1 mb-3">El cliente puede descargar la aplicación desde el código QR</h5>
    </div>
    <div class="footer d-flex justify-content-center align-middle" style="min-height: 120px;">
      <h4 class="col d-flex justify-content-center col-sm-2 footer-msg pr-0">Asegurado por</h4>
      <img src="../../assets/logo/mx_insurama.png" alt="QR" style="height: 100px;" class="mb-2">

    </div>
  </div>

</template>

<script>

export default {
  name: "ThirdStepIronPalace",
  props: {
      policyNumber: String,
      policyEmail: String
  },
  data() {
    return {
      
    }
  },
  methods: {
    goToFirstStep(){
      window.location.reload();
    }
  }
};
</script>

<style scoped>
.purchase-info {
  text-align: center;
}
.round-logo {
  position: absolute;
  top: -48px;
}
div {
  font-family: Gotham-Light;
}
h3 {
  font-family: Gotham-Bold;
}
h6 {
  font-family: Gotham-Bold;
}
.steps-info {
  min-height: 150px;
  background: #f5f7fb;
}
.steps-info h6 {
  font-family: Gotham-Light;
}
.align-items-center {
  display: flex; 
  align-items: center;  /*Aligns vertically center */
  justify-content: center; /*Aligns horizontally center */
}
.footer {
  background-color: #F4F4F4;
  border-top: 1px solid #bcbcbc;
}
button {
  text-align: center;
  padding-right: 30px;
  padding-left: 30px;
  min-width: 200px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 30px;
}
.continue {
  background-color: #EBB248;
  color: black;
  border: 0px;
  font-weight: bold;
  font-family: Gotham-Bold;
  border-radius: 0px !important;
}
.footer-msg {
  align-items: center;
  width: 30%;
}
.email-cliente {
  font-family: Gotham-Bold;
  padding-top: 0.2rem!important;
}
 .cancel {
    background-color: black;
    color: white;
    border: 0px;
    font-family: Gotham-Bold;
  }
</style>