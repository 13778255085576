<template>
    <div class="sb-input mt-0">
    <div class="icon-wrapper">
      <!-- <img :src="getImgUrl()" alt="" /> -->
    </div>
    <div class="input-wrapper">
      <span class="label mb-3">{{title | capitalize}}</span><br />
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: "BoxIconField",
  props: {
      imgUrl: String,
      title: String,
  },
  methods: {
    getImgUrl(){
      return require(`../../assets/palacio-hierro/${this.imgUrl}.svg`)
    }
  }
};
</script>

<style scoped>

.input-wraper {
  text-align: left;
  font-weight: bold;
}
.sb-input {
  margin-top: 4%;
} 
.label { 
  font-family: Gotham-Bold;
}
</style>