<template>
    <div class="deviceLogo">
        <div class="device px-2">
            <div class="icon-wrapper">
                <img :src="imgUrl">
            </div>
            <div>
                <span class="title">{{title}}</span>
            </div>
        </div>

        <div v-if="sideBar" class="vl"></div>

    </div>
</template>

<script>

export default {
  name: "DeviceLogo",
  props: {
      title: String,
      imgUrl: String,
      sideBar: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      
    }
  },
};
</script>

<style scoped>
    .deviceLogo{
        display: flex;
        justify-content: space-evenly;
    }

    .title{
        font-size: 10px;
        color: #868c90;
    }

    .icon-wrapper{
        height: 70px;
    }

    .vl {
        border-left: 2px solid #868c90;
        height: 70px;
    }
    
</style>