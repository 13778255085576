<template>
  <div class="gotham-light">
    <b-modal centered id="modal-1" hide-footer title="">
        <img style="max-width: 100%;" src="../../assets/palacio-hierro/ticket.png" alt="Ticket compra">
    </b-modal>
    <b-modal centered id="modal-6" hide-footer title="">
        <ol class="pt-4 pb-4">
          <li>Verifica que el ticket ingresado sea el correcto.</li>
          <li>En caso de error regresa a la pantalla de POS y cancela la venta.</li>
          <li>Realiza la venta con el producto correcto.</li>
          <li>Reingresa a esta pantalla y escanea tu ticket.</li>
          <li>Continua el proceso hasta terminar.</li>
        </ol>
    </b-modal>
    <b-modal centered id="modal-2" hide-footer title="" size="md">
      <div class="row pb-4">
        <div class="col-4 d-flex justify-content-center">EQUIPO NUEVO</div>
        <div class="col-1 line-sperarator mb-2"><span></span></div>
        <div class="col-7 gotham-bold d-flex justify-content-center">ADQUIRIDO EL DÍA DE HOY</div>
      </div>
      <div class="row pb-4">
        <div class="col-4 d-flex justify-content-center pt-2">EQUIPO USADO</div>
        <div class="col-1 line-sperarator mb-4"><span></span></div>
        <div class="col-7 gotham-bold d-flex justify-content-center">ADQUIRIDO CUALQUIER OTRO DÍA PREVIO</div>
      </div>
    </b-modal>
    <b-modal centered id="modal-3" hide-footer title="" size="md" class="gotham-light">
        <p >
        El régimen fiscal es un conjunto de derechos y
        obligaciones derivados del desarrollo de una actividad económica.</p>
        <p>
        Funciona como una guía al momento de cumplir con tus obligaciones fiscales.
        </p>
        <p>
        Si no conoce el régimen fiscal al que pertenece, en 
        caso de ser persona física puede optar por considerar 
        <span class="underline font-weight-bold"> el régimen general de personas físicas con actividad empresarial 
        y profesional</span> y en caso de ser persona moral, <span class="underline font-weight-bold">régimen general de ley personas morales.</span>
        </p>
       
    </b-modal>
    <b-modal centered id="modal-4" hide-footer title="" size="md" class="gotham-light">
        <p >
        El precio del dispositivo indicado no corresponde
        al SKU cobrado, para una correcta cobertura
        al cliente realiza los siguiente pasos:
        </p>
        <ol class="pt-4 pb-4">
          <li>Cancela la venta en la POS y el ticket generado para el cobro del seguro</li>
          <li>Realiza el cobro del seguro con SKU correcto</li>
          <li>Realiza la activación de la póliza del cliente en el Portal XXXX.</li>
        </ol>
    </b-modal>
    <b-modal centered id="modal-5" hide-footer title="" size="xl" class="gotham-light">
        <div class="row d-flex justify-content-center gotham-light">
          <p>La aplicación guiará al cliente en cada paso.</p>
        </div>
        <div class="row d-flex justify-content-center gotham-light text-center pb-4">
          <p>¡Es muy sencillo!</p>
        </div>
        <template v-if="step === 1">
          <div class="row d-flex justify-content-center pt-2">
            <div class="col-1 arrow"><img src="../../assets/reviews/arrowleft-ph.png" @click="step=2"></div>
            <div class="col-3 d-flex justify-content-center mt-2">
              <div class="card" style="width: 18rem;">
                <img class="card-img-top" src="../../assets/palacio-hierro/ayuda-verificacion-1.png" alt="Card image cap">
                <div class="card-body">
                  <p class="card-text p-2 gotham-light text-center">Se deberán tomar fotos del equipo sin funda de manera que se pueda apreciar correctamente.</p>
                </div>
              </div>
            </div>
            <div class="col-3 d-flex justify-content-center mt-2">
              <div class="card" style="width: 18rem;">
                <img class="card-img-top" src="../../assets/palacio-hierro/ayuda-verificacion-2.png" alt="Card image cap">
                <div class="card-body pr-1 pl-1">
                  <p class="card-text p-2 gotham-light text-center">Las fotos deberán tomarse frente a un espejo siguiendo las indicaciones de la aplicación.</p>
                </div>
              </div>
            </div>
            <div class="col-3 d-flex justify-content-center mt-2">
              <div class="card" style="width: 18rem;">
                <img class="card-img-top" src="../../assets/palacio-hierro/ayuda-verificacion-3.png" alt="Card image cap">
                <div class="card-body">
                  <p class="card-text p-2 gotham-light text-center">Este proceso podrá repetirlo el cliente de ser necesario. </p>
                </div>
              </div>
            </div>
            <div class="col-1 arrow"><img src="../../assets/reviews/arrowright-ph.png" @click="step=2"></div>
          </div>
        </template>
        <template v-else>
          <div class="row d-flex justify-content-center pt-2">
            <div class="col-1 arrow"><img src="../../assets/reviews/arrowleft-ph.png" @click="step=1"></div>
            <div class="col-3 d-flex justify-content-center mt-2">
              <div class="card" style="width: 18rem;">
                <img class="card-img-top" src="../../assets/palacio-hierro/firma-1.png" alt="Card image cap">
                <div class="card-body">
                  <p class="card-text gotham-light text-center">Deberá aceptar las cookies, revisar las condiciones de su póliza y dar FIRMAR.</p>
                </div>
              </div>
            </div>
            <div class="col-3 d-flex justify-content-center mt-2">
              <div class="card" style="width: 18rem;">
                <img class="card-img-top" src="../../assets/palacio-hierro/firma-2.png" alt="Card image cap">
                <div class="card-body">
                  <p class="card-text gotham-light text-center">Lo llevará a un recuadro en donde podrá firmar con su dedo y seleccionar el botón amarillo.</p>
                </div>
              </div>
            </div>
            <div class="col-3 d-flex justify-content-center mt-2">
              <div class="card" style="width: 18rem;">
                <img class="card-img-top" src="../../assets/palacio-hierro/firma-3.png" alt="Card image cap">
                <div class="card-body">
                  <p class="card-text gotham-light text-center">Con esto, el proceso quedará completo y la póliza de tu cliente totalmente activa.</p>
                </div>
              </div>
            </div>
            <div class="col-1 arrow"><img src="../../assets/reviews/arrowright-ph.png" @click="step=1"></div>
          </div>
        </template>
    </b-modal>
    <b-modal centered id="modal-7" hide-footer title="" size="xl">
        <div class="row my-4 py-2 d-flex justify-content-center">
          <div class="col-1"></div>
          <div class="col">
            <div class="information-title text-center">BRINDAMOS PROTECCIÓN ANTE SUCESOS COMO:</div>
            <div class="row icon-wrapper text-center w-100 mt-3 pt-5">
              <div class="help-section col-sm" v-bind:key="help.img" v-for="help in show_help">
                <img :src="help.img">
                <p>{{help.info}}</p>
              </div> 
            </div>
          </div>
          <div class="col-1"></div>
        </div>
        <div class="row my-4 py-2 d-flex justify-content-center text-center">
          <div class="col-1"></div>
          <div class="col w-100">
            <RangeTableInfo :rates="rates"></RangeTableInfo>
          </div>
          <div class="col-1"></div>
        </div>
    </b-modal>
    <b-modal centered id="modal-8" hide-footer title="" size="sm" hide-header-close>
      <p>Selecciona antes un tipo de seguro.</p>
    </b-modal>
  </div>
</template>

<script>
import RangeTableInfo from "@/components/ironPalace/RangeTableInfo.vue";

export default {
  name: "Modals",
  props: {
    show_help: Array,
    rates: Array
  },
  components: {
    RangeTableInfo
  },
  data() {
    return {
      step: 1
    }
  },
  created() {
  },
  methods: {
  },
};
</script>

<style scoped>

  .line-sperarator {
    border-bottom: 1px solid black;
  }
  .underline{
    text-decoration: underline;
  }
  .gotham-light {
    font-family: Gotham-Light;
  }
  .gotham-bold {
    font-family: Gotham-Bold;
  }
  /deep/ .modal-backdrop {
    background-color: #00000033 !important;
    color: white;
  }

   /deep/ .modal-header .close {
    font-size: 4rem;
      padding: 0 0.5rem 0.5rem;
      margin: -1rem -1rem -1rem auto;
  }
.card-body {
  background-color: #F2F2F2;
}
b-modal {
  z-index: 9999 !important;
}
.card {
  height: 43rem;
}
.card-img-top{
  height: 34rem;
  border: 4px solid #FEC72A;
}
.arrow {
  display: flex;
  align-items: center;
}
</style>