<template>
  <div class="sb-input">
    <div class="icon-wrapper">
      <img :src="getImgUrl()" alt="" />
    </div>
    <div class="input-wrapper">
      <span class="label">{{title | capitalize}} *</span><br />
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "BoxIcon",
  props: {
      imgUrl: String,
      title: String,
  },
  methods: {
    getImgUrl(){
      return require(`../../assets/${this.imgUrl}`)
    }
  }
};
</script>
<style lang="scss">
.sb-input .autocomplete-input{
    color: #495057;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    width: 100%;
    padding: 0 2px;
    outline: none;
    height: auto;

    :focus{
        outline: none;
        border: none;
        background-color: transparent;
        box-shadow: none;
    }
}

.order-tabs .b-form-datepicker>button {
    display: none;
}

.b-form-btn-label-control.form-control>.form-control{
    min-height: auto !important;
}
</style>
<style lang="scss" scoped>
  .sb-input {
    border-radius: 2000px;
    border: 1px solid #FF4D00;
    display: flex;
    align-items: center;
    padding: 5px;
    margin-bottom: 10px;

    .icon-wrapper {
        margin-left: 2px;
        background-color: #F7E7D7;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 26px;
            height: 26px;
        }
    }

    .input-wrapper {
        flex: 1;
        padding-left: 8px;
        margin-right: 32px;
        font-size: 13px;
        text-align: left;

        .label {
            font-weight: bold;
            text-transform: uppercase;
            color: #495371;
            font-size: 0.75rem;
        }

        input, select {
            appearance: none;
            background-color: transparent;
            border: none;
            box-shadow: none;
            color: #495371;
            font-size: 16px;
            padding-left: 0px;
            outline: none !important;
            width: 100%;
        }

        .b-form-datepicker{
            background-color: transparent;

            .btn{
                background-color: red;
            }
        }
    }

    &:focus-within {
        background-color: #F7E7D7;
        border: 2px solid #FF4C00 !important;

        .icon-wrapper {
            background-color: #FF4D00;
            img {
                filter: brightness(0) invert(1);
            }
        }

        .input-wrapper .label {
            color: #FF4C00;
        }
    }

    &.notValid {
      background-color: white !important;
      border: 1px solid #ff0000 !important;

      .icon-wrapper {
        background-color: #ff0000;
        img {
            filter: brightness(0) invert(1);
        }
      }
    }

    &.valid {
      background-color: white !important;
      border: 1px solid #0b6b0b !important;

      .icon-wrapper {
        background-color: #0b6b0b;
        img {
            filter: brightness(0) invert(1);
        }
      }
    }
}
</style>