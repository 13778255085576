const host = window.location.host;
const partsOfUrl = host.split('.');
var is_sandbox = false;
if (['sbx'].indexOf(partsOfUrl[1]) !== -1) {
    is_sandbox = true;
}
export default {
    seguromovil: is_sandbox ? 'segurocelular' : 'seguromovil',
    seguropatinete: 'seguropatinete',
    seguromultidispositivo: 'seguromultidispositivo',
    seguroportatil: 'seguroportatil',
    extensiondegarantia: 'extensiondegarantia',
    insurama: 'insurama'
}