const numberFormats = {
    en: {
        currency: { style: "currency", currency: "USD" }
    },
    es: {
        currency: { style: "currency", currency: "EUR", currencyDisplay: "symbol" }
    },
    fr: {
        currency: { style: "currency", currency: "EUR", currencyDisplay: "code" }
    }
}

export default numberFormats