const URL_INSURAMA = process.env.VUE_APP_INSURAMA_API_URL
var buildUrl = function(base, path) {
    return `${base}/${path}`
}

var buildUrlInsurama = function(path) {
    return buildUrl(URL_INSURAMA, path)
}

export default {
    DEVICE_MODELS: buildUrlInsurama('models'),
    CONTACT_POST: buildUrlInsurama('contacto-web'),
    DISTRIBUTOR_LANDINGS: buildUrlInsurama('distributors-landings'),
    VALIDATE_TICKET_PH: buildUrlInsurama('palacio-hierro-ticket'),
    REGIMEN_FISCAL: buildUrlInsurama('regimen-fiscal'),
    ADDRESS_BY_POSTCODE: buildUrlInsurama('address-by-postcode'),
    STORES_PH: buildUrlInsurama('palacio-stores'),
    CHECK_IP_PH: buildUrlInsurama('check-ip-palacio'),
}