const pageRoutes = {
    initial: '/',
    cookies: '/cookies',
    order: '/order',
    orderdiscounts: '/order-discount',
    ordererror: '/order-error',
    orderok: '/order-ok',
    error: '/error',
    contracting: '/contratacion/:landing'
}

if (process.env.VUE_APP_COUNTRY_CODE == 'it') {
    pageRoutes['contact'] = '/contatto'
    pageRoutes['map'] = '/mappa'
    pageRoutes['legalwarning'] = '/avviso-legale'
    pageRoutes['contractingconditions'] = '/condizioni-generali'
    pageRoutes['privacity'] = '/privacy'

} else if (process.env.VUE_APP_COUNTRY_CODE == 'pt') {
    pageRoutes['contact'] = '/contacto'
    pageRoutes['map'] = '/mapa'
    pageRoutes['legalwarning'] = '/aviso-legal'
    pageRoutes['contractingconditions'] = '/condicoes-gerais'
    pageRoutes['privacity'] = '/politica-privacidade'
} else if (process.env.VUE_APP_COUNTRY_CODE == 'mx') {
    pageRoutes['contact'] = '/contacto'
    pageRoutes['map'] = '/mapa'
    pageRoutes['discount'] = '/descuentos_in_a_box/:landing'
    pageRoutes['legalwarning'] = '/aviso-legal'
    pageRoutes['contractingconditions'] = '/condiciones-generales'
    pageRoutes['privacity'] = '/privacidad'
    pageRoutes['contractingPalace'] =  '/ph'
} else {
    pageRoutes['contact'] = '/contacto'
    pageRoutes['map'] = '/mapa'
    pageRoutes['legalwarning'] = '/aviso-legal'
    pageRoutes['contractingconditions'] = '/condiciones-generales'
    pageRoutes['privacity'] = '/privacidad'
}

module.exports = pageRoutes