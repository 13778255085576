<template>
  <div class="legality-items">
      <div class="col-12 col-lg-2">
          <img loading="lazy" :src="getLogoIcon()" height="70" class="logo">
      </div>
      <div class="items col-12 col-lg-10">
          <a target="_blank" v-if="isEnableOrderProcess && !isMxBroker()" href="/assets/pdf/informativa.pdf">{{ $t('footerComponent.informativa') }} </a>
          <router-link :to="{ name: 'legal-warning'}" target="_blank">{{ $t('footerComponent.legal') }}</router-link>
          <router-link v-if="isEnableOrderProcess" :to="{ name: 'contracting-conditions'}" target="_blank">{{ $t('footerComponent.conditions') }}</router-link>
          <a class="conditions-box" v-if="isEnableOrderProcess" href="/assets/pdf/condiciones_producto.pdf" target="_blank">{{ $t('footerComponent.product_conditions') }}</a>
          <router-link :to="{ name: 'privacity'}" target="_blank">{{ $t('footerComponent.privacity') }}</router-link>
          <router-link v-if="!isMxBroker()" :to="{ name: 'cookies'}" target="_blank">{{ $t('footerComponent.cookies') }}</router-link>
      </div>
  </div>
</template>
<script>

export default {
    name: "FooterLegality",
    methods: {
        getLogoIcon() {
            try {
                return require(`@/assets/logo/${this.$i18n.locale}_insurama.svg`)
            } catch (e) {
                return require(`@/assets/logo/${this.$i18n.locale}_insurama.png`)
            }
        },
        isMxBroker() {
            return this.$i18n.locale == 'mx'
        },
    },
    data(){
        return {
            isEnableOrderProcess: process.env.VUE_APP_SHOW_ORDER_PROCESS === 'true',
        }
    },
}
</script>
<style lang="scss" scoped>
.legality-items{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 20px 0;

    .logo {
        margin-bottom: 20px
    }

    .items{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        a {
            text-align: center;
            color: $blue-color;
            margin: 0 8px
          }
    }
}
</style>