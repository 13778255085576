<template>
    <div>
        <form @submit.prevent="saveData">
            <BoxIcon id="movilName" :class="isValidSelectedDeviceClass(this.devices, policyData.device, this.incorrectDevice, step1BtnClicked)" imgUrl="img/contracting/movil.svg"  :title="$t('order.devicePriceSelector.device_label_movil')">
                <autocomplete required id="autocomplete" @change="setPolicyModel" :search="searchDevice" :placeholder="$t('order.devicePriceSelector.select_device')" 
                    :aria-label="$t('order.devicePriceSelector.select_device')" :get-result-value="getResultValue" 
                    @submit="getDevice" @blur="getDevice"  base-class="autocomplete" ref="autocompleteElem"  >
                </autocomplete>
            </BoxIcon>
            <BoxIcon imgUrl="img/contracting/precio.svg" :class="isValidLimitedPriceClass(minPurchasePriceLimit,maxPurchasePriceLimit,policyData.purchasePrice, step1BtnClicked)"
            :title="$t('contractingLanding.phone_price')">
                <input
                    v-model="policyData.purchasePrice"
                    type="number"
                    onkeydown="return event.keyCode !== 69"
                    required
                    @change="updatePrice"
                    :placeholder="$t('contractingLanding.enter_price')"
                />
            </BoxIcon>
            <BoxIcon v-if="isMobileTheft()" imgUrl="img/contracting/fecha-de-compra.svg" :title="$t('order.devicePriceSelector.date_of_purchase')" class="date" :class="isValidPurchaseDateClass('seguromovil', policyData.purchaseDate, step1BtnClicked)">
                <b-form-datepicker v-model="policyData.purchaseDate" placeholder="dd/mm/aaaa" :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }" :max="maxDate" show-decade-nav hide-header  locale="es" ></b-form-datepicker>
            </BoxIcon>
            <BoxIcon v-if="isMobileTheft()" imgUrl="img/contracting/movil.svg" :class="isValidImeiNumberClass(policyData.imei, step1BtnClicked)"
            :title="$t('contractingLanding.imei')">
                <input
                    v-model="policyData.imei"
                    type="number"
                    onkeypress="return this.value.length < 15;"
                    onkeydown="return event.keyCode !== 69"
                    required
                    :placeholder="$t('contractingLanding.placeholder_imei')"
                />
            </BoxIcon>
            <Periodicity
                v-if="showPeriodicityOptions"
                :periodicity="policyData.periodicity"
                :showMonthlyPeriodicityOption="showMonthlyPeriodicityOption"
                @update="updatePeriodicity">
            </Periodicity>
            <BoxIcon imgUrl="img/contracting/numero_movil.svg" :class="isValidPhoneClass(policyData.phone, step1BtnClicked)"
             :title="$t('contractingLanding.phone')">
                <input
                    v-model="policyData.phone"
                    type="number"
                    required
                    onkeypress="return this.value.length < 10;"
                    onkeydown="return event.keyCode !== 69"
                    :placeholder="$t('contractingLanding.enter_phone')"
                    @blur="isValidPhone(policyData.phone) && pushGTM('funnel', 'telefono', 'paso1', '', productName)"
                />
            </BoxIcon>
            <button type="submit" class="btn btn-block btn-linear btn-siguiente">
                <span>{{$t('contractingLanding.continue')}}</span>
                <img class="ml-2" src="../../assets/img/contracting/next.svg" alt="">
            </button>
        </form>
        
    </div>
</template>

<script>
import BoxIcon from './BoxIcon.vue'
import Periodicity from './Periodicity.vue'
import Autocomplete from '@trevoreyre/autocomplete-vue'
import { getDeviceModels } from '@/api/ApiClient.js'
import '@trevoreyre/autocomplete-vue/dist/style.css'
import landing from '@/config/constants/landing.js';

export default {
    name: 'SmartphoneForm',
    components: {
        BoxIcon,
        Periodicity,
        Autocomplete
    },
    props: {
        showPeriodicityOptions: Boolean,
        showMonthlyPeriodicityOption: Boolean,
        initialPeriodicity: String,
        productName: String,
        currentLanding: String,
        minPurchasePriceLimit: Number,
        maxPurchasePriceLimit: Number,
    },
    data(){
        return{
            policyData: {
                model: '',
                purchasePrice: '',
                purchaseDate: '',
                phone: '',
                periodicity: '',
                imei: '',
                device: ''
            },
            devices: [],
            step1BtnClicked: false,
            deviceValidation: '',
            incorrectDevice: false,
        }
    },
    watch: {
        initialPeriodicity: {
            handler(newValue){
                this.policyData.periodicity = newValue;
            }, deep: true
        }


    },
    created(){
        this.getDevices();
        this.policyData.periodicity = this.initialPeriodicity;
    },
    computed: {
        device: {
            get(){
                return this.policyData.device;
            },

            set(value){
                this.policyData.device = value;
                this.deviceValidation = this.isValidSmartphoneDevice;
            }
        },
        isValidSmartphoneDevice() {
            if (!this.policyData.model && !this.step1BtnClicked) {
                return ''
            } else if ((!this.policyData.model && this.step1BtnClicked ) || this.policyData.model.length == 0) {
                return 'notValid'
            } else if (this.searchDevice(this.policyData.device.name).some((device)=> device.name === this.policyData.device.name) && this.policyData.device.name === this.policyData.model) {
                return 'valid'
            } else {
                return 'notValid'
            }
        },
    },
    methods: {
        getDevice(result) {
            this.policyData.model = document.getElementById('autocomplete').value
            if(this.devices.includes(result)){
                this.device = result;
                this.incorrectDevice = false;
                this.pushGTM('funnel', 'marca_extension', 'paso1', this.device?.name, this.policyData.product?.name, true);
            }else {
                if(this.$refs.autocompleteElem !== undefined && this.$refs.autocompleteElem.value !== ''&& !this.devices.some(device => device.name === this.$refs.autocompleteElem.value)){
                    this.incorrectDevice = true;
                    this.device = this.$refs.autocompleteElem.value;
                }else if(this.$refs.autocompleteElem !== undefined && this.$refs.autocompleteElem.value === ''){
                    this.device = '';
                    this.incorrectDevice = false;
                }
            }
        },
        updatePeriodicity(periodicity){
            this.policyData.periodicity = periodicity;
            this.updatePrice();
        },

        updatePrice() {
            this.pushGTM('funnel', 'precio', 'paso1', this.policyData.purchasePrice, this.productName, true);
            this.$emit("updatePrice",this.policyData);
        },

        saveData(){
            this.step1BtnClicked = true;
            if(this.isValidSmartphoneDevice!=='valid') return;

            if( this.policyData.periodicity === "1"){
                if(this.isValidOrderStep1(this.currentLanding, this.policyData) && this.isValidLimitedPrice(this.minPurchasePriceLimit, this.maxPurchasePriceLimit, this.policyData.purchasePrice))
                    this.$emit("save", this.policyData);
            }
            else{
                if(this.isValidOrderStep1(this.currentLanding, this.policyData))
                    this.$emit("save", this.policyData);
            }
        },

        async getDevices(){
            this.devices = await getDeviceModels()
        },

        searchDevice(textInput) {
            if (!textInput || textInput.length < 1) { return [] }
            
            return this.devices.filter(device => {
                    if(device.name !== null){
                        return device.name.toLowerCase()
                        .includes(textInput.toLowerCase())
                    }
            })
        },
        
        getResultValue(result) {
            return result.name
        },

        setPolicyModel(){
            this.getDevice()
        },

        

        isMobileTheft() {
            return this.currentLanding === landing.seguromovilrobo.name;
        }
    }
}

</script>
