<template>
  <div @change="onChangeUserData()">
  <template v-if="personType === 'individualPerson'">
    <div class="row py-3">
      <div class="col-sm-4 px-1">
          <input
          type="text"
          class="w-100"
          :class="setFieldClass(isValidTextClass(userData.name, 4))"
          v-model="userData.name"
          required
          placeholder="NOMBRE">
      </div>
      <div class="col-sm-4 px-1">
          <input
          type="text"
          class="w-100"
          :class="setFieldClass(isValidTextClass(userData.surname1, 4))"
          v-model="userData.surname1"
          required
          placeholder="APELLIDO 1">
      </div>
      <div class="col-sm-4 px-1">
          <input
          type="text"
          class="w-100"
          :class="setFieldClass(isValidTextClass(userData.surname2, 4))"
          v-model="userData.surname2"
          required
          placeholder="APELLIDO 2">
      </div>
      </div>
  </template>
  <template v-else>
    <div class="row py-3">
      <div class="px-1 col-12">
          <input
          type="text"
          class="w-100"
          :class="setFieldClass(isValidTextClass(userData.name, 4))"
          v-model="userData.name"
          required
          placeholder="RAZÓN SOCIAL">
      </div>
    </div>
  </template>
      <div class="row py-3">
      <div class="col-sm-6 px-1">
          <input
          type="number"
          class="w-100"
          :class="setFieldClass(isValidPhoneClass(userData.phone))"
          v-model="userData.phone"
          required
          placeholder="CELULAR"
          onkeypress="return this.value.length < 10"
          onkeydown="return event.keyCode !== 69">
      </div>
      <div class="col-sm-6 px-1">
          <input
          type="email"
          class="w-100"
          :class="setFieldClass(isValidEmailClass(userData.email))"
          v-model="userData.email"
          required
          placeholder="EMAIL">
      </div>
      </div>
      <div class="row py-3">
      <div class="col-sm-4 px-1">
          <input
          type="text"
          class="w-100"
          :class="setFieldClass(isValidTextClass(userData.street, 3))"
          v-model="userData.street"
          required
          placeholder="CALLE">
      </div>
      <div class="col-sm-2 px-1">
          <input
          type="number"
          class="w-100"
          :class="setFieldClass(isValidNumberClass(userData.exterior_number, 1))"
          v-model="userData.exterior_number"
          required
          onkeydown="return event.keyCode !== 69"
          placeholder="#">
      </div>
      <div class="col-sm-4 px-1">
          <input
          type="text"
          class="w-100"
          :class="setFieldClass(isValidTextClass(userData.colony, 3))"
          v-model="userData.colony"
          required
          placeholder="COLONIA">
      </div>
      <div class="col-sm-2 px-1">
          <input
          type="number"
          onkeydown="return event.keyCode !== 69"
          class="w-100"
          @change="validatePostalCode()"
          :class="!userData.postal_code ? '' : isValidNumberClass(userData.postal_code, 4) === 'valid' && isValidPostalCode ? 'val' : 'notVal'"
          v-model="userData.postal_code"
          required
          placeholder="CP">
      </div>
      </div>
      <div class="row py-2">
      <div class="col-sm-4 px-1 pt-4">
          <input
          type="text"
          class="w-100"
          :class="showMessageErrorMonth || showMessageErrorDay ? 'notVal' : setFieldClass(personType === 'individualPerson' ? validateRFC : isValidMoralPersonClass(userData.nif, userData.birth_date))"
          v-model="userData.nif"
          @blur="validateMonthDay()"
          required
          placeholder="RFC">
          <span class="input-help-error" v-if="showMessageErrorMonth">El mes indicado en el RFC es incorrecto</span>
          <span class="input-help-error" v-if="showMessageErrorDay && !showMessageErrorMonth">El día indicado en el RFC es incorrecto</span>
          <span class="input-help-error" v-if="personType === 'individualPerson' && showMessageErrorAge && !showMessageErrorDay && !showMessageErrorMonth">La contratación no está habilitada para menores de 18 años</span>    
      </div>
      <div class="col-sm-8 px-1">
          <div class="title-tax">
            <span>RÉGIMEN DE SITUACIÓN FISCAL</span>
          </div>
          <div class="dropdown-question">
            <select v-model="userData.regimen_fiscal_code" required :class="setFieldClass(isValidNumberClass(this.userData.regimen_fiscal_code),3)">
                <option v-for="(status, index) in statuses" :key="index" :value="status.id">{{status.text}}</option>
            </select>
          <div>
              <b-button style="background-color: transparent" v-b-modal.modal-3><img class="help-btn p-2" src="../../assets/palacio-hierro/interrogacion.svg"></b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row checks">
      <div class="row">
        <div class="col">
          <label :for="acceptance_type" class="checkbox ml-0 pl-0"> <span class="checks-text"> Acepto los <a style="font-family: Gotham-Bold" target="_blank" href="/assets/pdf/condiciones_producto.pdf"><strong>Términos y Condiciones</strong></a> de uso de medios electrónicos y el tratamiento de mis datos personales de acuerdo a lo establecido en el <a style="font-family: Gotham-Bold" target="_blank" href="/assets/pdf/privacypolicy_mx.pdf"><strong>Aviso de Privacidad</strong></a></span>
            <input
                type="checkbox"
                v-model="userData.acceptance_type"
                :id="acceptance_type"
            >
            <span class="checkmark mt-4 mr-4" :class="setFieldClass(isValidAcceptanceTypeClass(userData.acceptance_type, btnContinueClicked))"></span>
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label :for="acceptance_type_2" class="checkbox ml-0 pl-0 checks-text">{{$t('order.userInputs.terms_2', { landing: this.currentLanding })}}
            <input
                type="checkbox"
                v-model="userData.acceptance_type_2"
                :id="acceptance_type_2"
            >
            <span class="checkmark mt-2 mr-4" :class="setFieldClass(isValidAcceptanceTypeClass(userData.acceptance_type_2, btnContinueClicked))" ></span>
        </label>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { getTaxStatusAll, getAddressByPostcode } from '@/api/ApiClient.js'

export default {
  name: "UserData",
  props: {
    clearUserData: Boolean,
    personType: String,
    btnContinueClicked: Boolean
  },
  data() {
    return {
      statuses: [],
      userData: {
          name: '',
          surname1: '',
          surname2: '',
          phone: '',
          email: '',
          birth_date: '',
          state: '',
          town: '',
          street: '',
          exterior_number: '',
          colony: '',
          postal_code: '',
          nif: '',
          regimen_fiscal_code: '605',
          acceptance_type: 0,
          acceptance_type_2: 0
        },
        isValidPostalCode: false,
        showMessageErrorMonth: false,
        showMessageErrorDay: false,
        showMessageErrorAge: false
    }
  },
   created(){
      this.getStatuses();
  },
  updated() {
    //this.setUserDataByPersonType(this.personType);
  },
  methods: {
    async getStatuses(){
      try{
        const estados = await getTaxStatusAll();
        Object.keys(estados).forEach((x) => this.statuses.push({text: (x + ' - '+ estados[x]), id: x}));
      }
      catch(error){
        this.$emit('error')
      }
    },

    onChangeUserData() {
      let birth_date = '';
      if(this.userData.nif !== ''){
        if (this.personType === 'individualPerson'){
          const day = this.userData.nif.substr(8, 2)
          const month = this.userData.nif.substr(6, 2)
          let year = this.userData.nif.substr(4, 2)
          const currentYear = new Date().getFullYear().toString().substr(2, 2)
          if (currentYear > year) {
            year = `20${year}`;
          } else {
            year = `19${year}`;
          }
          birth_date = `${day}/${month}/${year}`
        } else {
          const day = this.userData.nif.substr(7, 2)
          const month = this.userData.nif.substr(5, 2)
          let year = this.userData.nif.substr(3, 2)
          const currentYear = new Date().getFullYear().toString().substr(2, 2)
          if (currentYear >= year) {
            year = `20${year}`;
          } else {
            year = `19${year}`;
          }
          birth_date = `${day}/${month}/${year}`
        }
        this.userData.birth_date = birth_date;
      }
      const data = {
        ...this.userData,
       name: this.personType === 'individualPerson' ? `${this.userData.name} ${this.userData.surname1} ${this.userData.surname2}` : this.userData.name,
        birth_date
      }
      this.$emit('userDataUpdated', data)
    },

    setFieldClass(value){
      if(value === 'valid') return 'val'
      else if(value === 'notValid') return 'notVal'
      else return '';
    },
    async validatePostalCode() {
      const postalCodeData = await getAddressByPostcode(this.userData.postal_code);
      if (postalCodeData?.valid_postcode) {
        this.isValidPostalCode = true;
        this.userData.state = postalCodeData.estado;
        this.userData.town = postalCodeData.municipio;
      } else {
        this.isValidPostalCode = false;  
        this.userData.state = '';
        this.userData.town = '';
      }
      this.onChangeUserData()
    },
    setUserDataByPersonType(newP) {
      if(newP == 'legalEntity') {
        this.userData.regimen_fiscal_code = '601'
        this.userData.surname1 = ''
        this.userData.surname2 = ''
      }
      else this.userData.regimen_fiscal_code = '605'

    },
    validateMonthDay(){
         this.showMessageErrorMonth = false; 
         this.showMessageErrorDay = false;
         this.showMessageErrorAge = false;
         if(this.userData.nif !== '' && this.validateMonthAndDay(this.userData.birth_date) === 'invalidMonth') this.showMessageErrorMonth = true 
         else if(this.userData.nif !== '' && this.validateMonthAndDay(this.userData.birth_date) === 'invalidDay') this.showMessageErrorDay = true
         else if(this.isValidPersonAge(this.userData.birth_date) === 'invalidAge') this.showMessageErrorAge = true
         else{
          this.showMessageErrorMonth = false; 
          this.showMessageErrorDay = false;
        }
    }
  },
  watch: {
    clearUserData: {
      handler(newVal){
        if(newVal){
          Object.keys(this.userData).forEach((x) => this.userData[x] = '')
          this.$emit('resetClearInfo')
        }
      },
      deep: true
    },
    personType(newP){
      this.setUserDataByPersonType(newP)
    }
  },
  computed: {
    validateRFC(){
      return this.isValidNifNieClass(this.userData.nif) === 'notValid' || this.isValidBirthDateClass(this.userData.birth_date, this.btnContinueClicked) === 'notValid' ? 'notValid' : 
      (this.isValidNifNieClass(this.userData.nif) === 'valid' && this.isValidBirthDateClass(this.userData.birth_date, this.btnContinueClicked) === 'valid') ? 'valid' : ''
    }
  }
}

</script>
<style scoped>
  input{
    outline: 0;
    border-width: 0 0 1px;
    border-color: gray;
    letter-spacing: 1px;
    font-size: 14px;
  }

   input.val{
    border-color: green;
    border-width: 0 0 2px;
  }

  select.val{
    border-color: green;
    border-width: 2px 2px 2px;
  }


  input.notVal{
    border-color: red;
    border-width: 0 0 2px;
  }

  select.notVal{
    border-color: red;
    border-width: 2px 2px 2px;
  }

  select{
    width: 350px;
    height: 35px;
    border-radius: 7px;
    color: gray;
    font-size: 12px;
    padding: 4px 10px;
    text-align: left;
  }

  .dropdown-question{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
  }

  .dropdown-question div{
    height: 35px;
    width: 30px;
    border-radius: 50%;
    position: relative;
  }

  .dropdown-question div img{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .title-tax{
    text-align: left;
    font-size: 12px;
    color: gray;
  }

  .dropdown-question select {
    width: 85%;
  }

  .dropdown-question img {
    border-radius: 50%;
    box-shadow: 0px 1px 1px 0px grey;
    padding: 0.2rem
  }
  .check{
    display: flex;
    gap:10px;
    font-size: 12px;
    text-align: left;
  }

  .checks{
      margin: 20px 0 20px 0;
  }
  .checkmark {
    position: absolute;
    left: -46px !important;
  }
  a {
    color: black;
    font-size: 0.9rem;
  }

  .checks-text, .checks-text a{
    font-size: 0.8rem;
  }

  .input-help-error {
    color: red;
    font-size: 0.8rem;
  }

</style>