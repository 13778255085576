<template>
  <div class="range-table">
    <table class="table" @change="rangeChecked($event)">
      <thead>
        <tr>
          <th class="font-weight-bold">RANGO</th>
          <th class="font-weight-bold text-center">PAGO</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(rate, index) in rates" :key="index">
          <td class="range-text">
            <label :for="rate.name+1" class="checkbox">{{rate.name.toUpperCase()}}
                <input
                    type="checkbox"
                    :name="rate.name"
                    :checked="productCategoryId==rate.pivot.product_category_id"
                    :value="rate.pivot.product_category_id"
                    :id="rate.name+1"
                    >
                <span class="checkmark"></span>
            </label>
          </td>
          <td class="price-text font-weight-bold text-center">$ {{rate.pivot.price}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: "RangeTableInfo",
  props: {
    rates: [],
  },
  data() {
    return {
      productCategoryId: null,
    };
  },
  methods: {
    rangeChecked(range) {
      this.productCategoryId = range.srcElement.checked ? range.target.value : null;
      this.$emit('rangeSelected',this.productCategoryId);
    }
  },
};
</script>

<style scoped>

th, .price-text {
  font-family: Gotham-Bold;
  border: 0px !important;
}

.range-text {
  font-family: Gotham-Book;
}
td {
  border-top: 0px;
}

.table {
  border: 1px solid black !important;
}

</style>