<template>
<div class="d-flex flex-column align-items-center">
  <div class="text-center p-2 mb-1">
    <div class="d-flex justify-content-between align-items-center p-0">
        <DeviceLogo :title="firstDevice" :imgUrl="getImgUrl(firstImg)" :sideBar="exmt"></DeviceLogo>
        <DeviceLogo :title="secondDevice" :imgUrl="getImgUrl(secondImg)" :sideBar="exmt"></DeviceLogo>
        <DeviceLogo :title="thirdDevice" :imgUrl="getImgUrl(thirdImg)" :sideBar="exmf"></DeviceLogo>
    </div>
  </div>
  <label class="radio">

    <input type="radio" :checked="value===selected" name="deviceType" @click="$emit('selected', value)"/>

    <span></span>

    </label>
</div>
</template>

<script>
import DeviceLogo from './DeviceLogo.vue'

export default {
  name: "DeviceOption",
  components:{
    DeviceLogo
  },
  props: {
      firstDevice: String,
      secondDevice: String,
      thirdDevice: String,
      firstImg: String,
      secondImg: String,
      thirdImg: String,
      value: Number,
      selected: Number,
  },
  data() {
    return {
      exmt: true,
      exmf: false
    }
  },
  methods: {
    getImgUrl(img){
      return require (`../../assets/palacio-hierro/${img}.svg`)
    }
  }
};
</script>

<style scoped>
 .deviceLogo{
    width: 33%;
    margin: auto;
  }
</style>