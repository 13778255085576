<template>
  <transition name="">
     <b-modal ref="callRequestRef" centered hide-header hide-footer>
        <div class="modal-center">
          <a class="btn-close-modal pr-4 pointer" @click="hideModal" style="font-size:30px;">
            <mdi-icon icon="mdiClose" />
          </a>
          <br>
          <div class="text-center pt-4">
            <form class="container" @submit.prevent="submitCallRequest" id="form-normal">
              <div class="row">
                <div class="col-12 col-md-10 offset-md-1">
                  <div v-if="!isHoliday()">
                    <h3 class="inner-title-modal mb-4">{{ $t('callRequestModal.request_call') }}</h3>        
                    <div class="form-group mt-4" v-if="showQueryBox == 'true'">
                      <div class="input-group">
                        <select onchange="rellenar_detalles_C2C(this.value,\''.$landing.'\');" class="form-control" :placeholder="$t('callRequestModal.select_title')" required name="consulta" id="consulta">
                            <option value="">{{ $t('callRequestModal.select_title') }}</option>
                            <option value="contratacionC2C" v-if="isEnableOrderProcess">{{ $t('callRequestModal.new_insurance') }}</option>
                            <option value="siniestroC2C">{{ $t('callRequestModal.sinister') }}</option>
                            <option value="">{{ $t('callRequestModal.distributor') }}</option>       
                            <option value="SAC-C2C">{{ $t('callRequestModal.client_service') }}</option>            
                          </select>
                          <div class="input-group-append">
                            <div class="input-group-text">
                              <mdi-icon icon="mdiHelpCircle" />
                            </div>
                          </div>
                      </div>
                    </div>  
                     <div class="form-group mt-4">
                      <div class="input-group">
                        <input id="telefono" name="telefono" class= 'form-control' :class="isValidPhoneClass(phone, btnClicked)" v-model="phone" :placeholder="$t('callRequestModal.write_your_phone')" />
                        <div class="input-group-append ">
                          <div class="input-group-text" :class="isValidPhoneClass(phone, btnClicked)">
                            <mdi-icon icon="mdiPhone"/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="custom-control custom-checkbox aceptar-condiciones mt-1 mb-2 text-left">
                      <input type="checkbox" id="privacity" name="privacidad" class="custom-control-input" style="margin-top:-20px;" v-model="policyChecked">
                      <label class="custom-control-label privacity" :class="isValidCheckClass(policyChecked, btnClicked)" for="privacity" v-html="$t('callRequestModal.privacity')"></label>
                    </div>
                    <div class="col-12">
                      <br>
                      <button button type="submit" class="btn-block btn-orange">{{ $t('callRequestModal.free_call') }}</button>
                      <br>
                    </div>
                    <div class="col-12" style="text-alling:center;">
                        <p class="inner-title-modal">{{ $t('callRequestModal.call_us') }}</p>
                        <a :href="`tel:${contactInfo.telephone}`" class="btn-blue btn-block mb-4"><span><i class="mr-2"><mdi-icon icon="mdiPhone" /></i>{{ contactInfo.telephone }}</span></a>
                        <!-- TODO: Remove v-if when enable whatsapp -->
                        <p v-if="contactInfo.whatsappIsEnabled && !isHoliday()" class="inner-title-modal">{{ $t('callRequestModal.better_write_us') }}</p>
                        <a v-if="contactInfo.whatsappIsEnabled && !isHoliday()" class="btn-blue btn-block mb-4 text-uppercase" tabindex="0" title="Whatsapp" :href="`https://api.whatsapp.com/send?phone=${contactInfo.whatsapp}`"><span data-nosnippet="">WHATSAPP</span></a>
                    </div>
                  </div>
                  <div v-if="isHoliday()">
                    <h3 class="inner-title-modal mb-4">{{ $t('callRequestModal.contact_by') }}</h3>
                    <div class="col-12" style="text-alling:center;">
                      <button @click="pushToContact()" class="btn-blue btn-block mb-3">EMAIL</button>
                      <!--<button type="button" class="btn-blue btn-block">{{ $t('callRequestModal.chat') }}</button>-->
                    </div>
                    <br>
                  </div>
                  <div v-if="showSinesterBox" class="col-12" style="text-alling:center;">
                      <p class="inner-title-modal">{{ $t('callRequestModal.need_help') }}</p>
                      <a href="javascript:void(0)" class="btn-white-border btn-block" @click="showSinisterModal"><span>{{ $t('callRequestModal.declare_sinister') }}</span></a><br>
                      <a @click="pushToContact()" class="btn-white-border btn-block pointer"><span>{{ $t('callRequestModal.become_a_distributor') }}</span></a>
                  </div>
                </div>
              </div>
              <br>
          </form> 
        </div>
      </div>
    </b-modal>
  </transition>
</template>

<script>
/* API-REST */
import { getGenericItemParams } from '@/api/ApiClientParamsSetter.js'
import { createOrUpdateGenericItem } from '@/api/ApiClient.js'
import contactInfo from '@/config/constants/contactInfo.js'

export default {
  name: 'CallRequestModal',
  props: {
    phoneContact: { type: String },
    showHelpBox: { default: true },
    showQueryBox: { default: 'true' },
    showSinesterBox: { default: true }
  },
  data() { 
    return {
      phone: "",
      btnClicked: false,
      policyChecked: false,
      contactInfo,
      isEnableOrderProcess: process.env.VUE_APP_SHOW_ORDER_PROCESS === 'true',
    }
  },  
  methods: {
    showModal() {
      this.$refs.callRequestRef.show()
    },
    hideModal() {
      this.pushGTM('funnel', 'cancelar', 'recurrente')
      this.$refs.callRequestRef.hide()
    },
    showSinisterModal() {
      this.$emit("show-report-sinister");
      this.$refs.callRequestRef.hide()
    },
    showThanksModal() {
      this.$emit("show-thanks");
      this.$refs.callRequestRef.hide()
    },
    pushToContact(){
      this.hideModal()
      if(this.$router.currentRoute.name!=='contact'){
        this.$router.push({ name:'contact', query: { opt: this.$t('callRequestModal.distributor')}})
      } else {
        this.$router.go()
      }
    },
    submitCallRequest() {
      this.btnClicked = true
      if(this.isValidPhone(this.phone) && this.policyChecked){
        //Realizar llamada
        //window.enviar_lead_SDS(e.target)
        this.pushGTMUserID(this.phone)
        this.sendClickToCallAnalytic();
        this.showThanksModal()
      }
    },
    async sendClickToCallAnalytic() {
        /* Carrito abandonado */
        if (this.isValidPhone(this.phone)) {
            const id = document.cookie.match('(^|;)\\s*_ga\\s*=\\s*([^;]+)')?.pop() || 'GA';

            if (!this.phone.trim().startsWith("+")) {
                this.phone = contactInfo.prefix + this.phone
            }
            var params = getGenericItemParams(this.currentLanding, id, {"phone":this.phone}, "click_to_call");
            var result = await createOrUpdateGenericItem(params); 
            console.log(result);
        }
    }
  }
}
</script>

<style lang="scss">

.inner-title-modal {
    color: $orange-color;
    font-weight: 600;
    font-size: 1.313em;
}

.privacity {
    font-family: 'Source Sans Pro';
    color: $blue-color-light;
    font-size: 0.7rem;
}

.privacityText {
    color: $blue-color-light;
}

</style>
