const contactInfo = {}

if (process.env.VUE_APP_COUNTRY_CODE == 'it') {
    contactInfo['telephone'] = '0694808001'
    contactInfo['email'] = 'clientes@insurama.it'
    contactInfo['prefix'] = '+39'
    contactInfo['whatsapp'] = '390694808001'
    contactInfo['whatsappIsEnabled'] = false
    contactInfo['holidays'] = ['1-1', '6-1', '4-4', '5-4', '25-4', '1-5', '2-6', '29-6', '15-8', '1-10', '8-12', '25-12', '26-12', '31-12']
    contactInfo['androidLogo'] = 'https://www.sella.it/banca-online/static/landing/app-invest/img/google-play-badge@2x.png'
    contactInfo['android'] = "https://play.google.com/store/apps/details?id=es.sumbroker.tuseguromovil"
    contactInfo['ios'] = "https://itunes.apple.com/es/app/tuseguromovil/id1435488576?l=es&amp;ls=1&amp;mt=8"
    contactInfo['iosLogo'] = 'https://www.sella.it/banca-online/static/landing/app-invest/img/app-store-badge@2x.png'
} else if (process.env.VUE_APP_COUNTRY_CODE == 'pt') {
    contactInfo['telephone'] = '308804174'
    contactInfo['prefix'] = '+351'
    contactInfo['email'] = 'clientes@insurama.pt'
    contactInfo['whatsapp'] = ''
    contactInfo['whatsappIsEnabled'] = false
    contactInfo['holidays'] = ['1-1', '28-2', '15-4', '17-4', '18-4', '25-4', '1-5', '20-5', '10-6', '16-6', '13-6', '15-8', '5-10', '1-11', '1-12', '8-12', '25-12']
    contactInfo['androidLogo'] = require('@/assets/apps/googleplay.png')
    contactInfo['iosLogo'] = require('@/assets/apps/appstoreios.jpg')
    contactInfo['android'] = "https://play.google.com/store/apps/details?id=com.insurama.seusegurotelemovel"
    contactInfo['ios'] = "https://itunes.apple.com/es/app/tuseguromovil/id1435488576?l=es&amp;ls=1&amp;mt=8"
} else if (process.env.VUE_APP_COUNTRY_CODE == 'mx') {
    contactInfo['telephone'] = '5559854280'
    contactInfo['prefix'] = '+52'
    contactInfo['email'] = 'clientes@insurama.mx'
    contactInfo['whatsapp'] = '910791764'
    contactInfo['whatsappIsEnabled'] = false
    contactInfo['holidays'] = ['1-1', '7-2', '21-3', '15-4','2-5', '16-9', '21-11', '25-12']
    contactInfo['androidLogo'] = require('@/assets/apps/googleplay_es.png')
    contactInfo['iosLogo'] = require('@/assets/apps/apple_es.png')
    contactInfo['android'] = "https://play.google.com/store/apps/details?id=es.insurama.tuseguromovil"
    contactInfo['ios'] = "https://apps.apple.com/mx/app/insurama-mx/id1593388457"
} else {
    contactInfo['telephone'] = '0694808001'
    contactInfo['prefix'] = '+39'
    contactInfo['whatsapp'] = '390694808001'
    contactInfo['androidLogo'] = 'https://www.sella.it/banca-online/static/landing/app-invest/img/google-play-badge@2x.png'
    contactInfo['whatsappIsEnabled'] = false
    contactInfo['android'] = "https://play.google.com/store/apps/details?id=es.sumbroker.tuseguromovil"
    contactInfo['ios'] = "https://itunes.apple.com/es/app/tuseguromovil/id1435488576?l=es&amp;ls=1&amp;mt=8"
    contactInfo['iosLogo'] = 'https://www.sella.it/banca-online/static/landing/app-invest/img/app-store-badge@2x.png'
}

module.exports = contactInfo