<template>
    <div>
        <form id="laptopForm" @submit.prevent="saveData">
        <BoxIcon imgUrl="img/contracting/tipo-portatil.svg" :title="$t('order.devicePriceSelector.device_label_laptop')" :class="isValidTextClass(policyData.model, step1BtnClicked)">
            <input
            v-model="policyData.model"
            type="text"
            required
            :placeholder="$t('order.devicePriceSelector.device_label_laptop')"
            minlength="4"
            @blur="isValidTextField(policyData.model, 4) && pushGTM('funnel', 'marca_portatil', 'paso1', policyData.model, productName)"
            />
        </BoxIcon>
        <BoxIcon imgUrl="img/contracting/precio.svg" :title="$t('contractingLanding.laptop_price')" :class="isValidLimitedPriceClass(minPurchasePriceLimit,maxPurchasePriceLimit,policyData.purchasePrice, step1BtnClicked)">
            <input
                v-model="policyData.purchasePrice"
                type="number"
                required
                onkeydown="return event.keyCode !== 69"
                :placeholder="$t('contractingLanding.laptop_price')"
                @change="updatePrice"
            />
        </BoxIcon>
        <BoxIcon imgUrl="img/contracting/referencia.svg" :title="$t('order.devicePriceSelector.reference')" :class="isValidTextClass(policyData.reference, step1BtnClicked)">
            <input
                v-model="policyData.reference"
                type="text"
                required
                placeholder="p.e. X123XX2"
                @blur="isValidTextField(policyData.reference, 1) && pushGTM('funnel', 'referencia', 'paso1', '', productName)"
            />
        </BoxIcon>
        <BoxIcon imgUrl="img/contracting/fecha-de-compra.svg" :title="$t('order.devicePriceSelector.date_of_purchase')" class="date" :class="isValidPurchaseDateClass('seguroportatil', policyData.purchaseDate, step1BtnClicked)">
            <b-form-datepicker v-model="policyData.purchaseDate" placeholder="dd/mm/aaaa" :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }" :max="maxDate" show-decade-nav hide-header  locale="es" ></b-form-datepicker>
        </BoxIcon>
        <Periodicity
            v-if="showPeriodicityOptions"
            :periodicity="policyData.periodicity"
            :showMonthlyPeriodicityOption="showMonthlyPeriodicityOption"
            @update="updatePeriodicity">
        </Periodicity>
        <BoxIcon imgUrl="img/contracting/numero_movil.svg" :title="$t('contractingLanding.phone')" :class="isValidPhoneClass(policyData.phone, step1BtnClicked)">
            <input
                v-model="policyData.phone"
                type="number"
                required
                onkeypress="return this.value.length < 10;"
                onkeydown="return event.keyCode !== 69"
                maxlength="10"
                minlength="10"
                :placeholder="$t('contractingLanding.enter_phone')"
                @blur="isValidPhone(policyData.phone) && pushGTM('funnel', 'telefono', 'paso1', '', productName)"
            />
        </BoxIcon>
        <button type="submit" class="btn btn-block btn-linear btn-siguiente">
            <span>{{$t('contractingLanding.continue')}}</span>
            <img class="ml-2" src="../../assets/img/contracting/next.svg" alt="">
        </button>
    </div>
</template>

<script>
import BoxIcon from './BoxIcon.vue'
import Periodicity from './Periodicity.vue'
import landing from '@/config/constants/landing.js';

export default {
    name: 'LaptopForm',
    components: {
        BoxIcon,
        Periodicity
    },
    props: {
        showPeriodicityOptions: Boolean,
        showMonthlyPeriodicityOption: Boolean,
        initialPeriodicity: String,
        productName: String,
        minPurchasePriceLimit: Number,
        maxPurchasePriceLimit: Number,
    },
    data(){
        return{
            policyData: {
                model: '',
                purchasePrice: '',
                reference: '',
                purchaseDate: '',
                phone: '',
                periodicity: '',
            },
            step1BtnClicked: false,
        }
    },
    created() {
        this.policyData.periodicity = this.initialPeriodicity;
    },
    watch: {
        initialPeriodicity: {
            handler(newValue){
                this.policyData.periodicity = newValue;
            }, deep: true
        }
    },
    methods: {
        saveData(){
            this.step1BtnClicked = true;
             if( this.policyData.periodicity === "1"){
                 if(this.isValidOrderStep1(landing.seguroportatil.name, this.policyData) && this.isValidLimitedPrice(this.minPurchasePriceLimit, this.maxPurchasePriceLimit, this.policyData.purchasePrice))
                    this.$emit("save", this.policyData);
             }
             else{
                if(this.isValidOrderStep1(landing.seguroportatil.name, this.policyData))
                    this.$emit("save", this.policyData);
             }
            
        },
        updatePrice() {
            this.pushGTM('funnel', 'precio', 'paso1', this.policyData.purchasePrice, this.productName, true);
            this.$emit("updatePrice",this.policyData);
        },
        updatePeriodicity(periodicity){
            this.policyData.periodicity = periodicity;
            this.updatePrice();
        },
    }
}

</script>

<style lang="scss" scoped>


</style>