<template>
    <div id="app">
        <transition appear name="fade" mode="out-in">
            <div v-if="isLoading" class="loading-overlay" key="loading">
                <img src='@/assets/loader.gif' height=64>
            </div>
            <div v-else>
                <router-view key="view" />
            <!--<Nav v-on:localeChange="loadLocaleMessages" />-->
            </div>
        </transition>
    </div>
</template>

<script>
import {loadLocaleMessagesAsync} from "@/i18n"
import {getters} from '@/state/store.js'
import { actions } from '@/state/store'

export default {
    data: () => ({
        isLoading: true
    }),
    beforeMount() {
        this.setFavText()
        this.pushUTM()
    },
    async mounted() {
        await this.initializeSite()

        if (!window.inject) {
            this.$cookiebot.consentBanner()
        }
    },
    watch: {
      $route: {
        handler: function(){
          this.pushUTM()
        },
        deep: true
      }
    },
    computed: {
        currentLanding: getters.currentLanding
    },
    methods: {
        /**
         * Initializes the site with all the basic data, keeping a load indicator while it does that
         */
        async initializeSite () {
            this.isLoading = true

            Promise.all([
                this.loadLocaleMessages(this.$i18n.locale),
                actions.getProducts()
            ])
            // If something fails at this point is critical, as some required data/locales are missing
            .catch(() => {
                this.$router.push({ name: 'error'})
            })
            .then(() => this.setFavicon() )
            // If something fails at this point is not critical, so we can continue with the normal page load
            .catch((err) => {
                console.warn(err)
            })
            .finally(() => this.isLoading = false )
        },
        loadLocaleMessages(locale) {
            localStorage.setItem("appLanguage", locale)
            return loadLocaleMessagesAsync(locale)
        },
        setFavicon() {
            var favicon = document.querySelector("link[rel~='icon']");
            favicon.href = require(`@/assets/fav-icons/${this.$i18n.locale}_${this.currentLanding}.png`)
        },
        setFavText() {
            var title = ''
            const locale = this.$i18n.locale
            if (this.isCurrentLanding('seguromovil')) {
                if (locale == 'it') {
                    title = 'Tusmartphoneassicurazione'
                }

                if (locale == 'pt') {
                    title = 'seuSegurotelemóvel'
                }

                if (locale == 'mx') {
                    title = 'segurocelular'
                }
            } else if (this.isCurrentLanding('seguromultidispositivo')) {
                if (locale == 'it') {
                    title = 'Tumultidispositivoassicurazione'
                }

                if (locale == 'pt') {
                    title = 'seuSeguromulti-dispositivos'
                }

                if (locale == 'mx') {
                    title = 'seguromultidispositivo'
                }
            } else if (this.isCurrentLanding('seguroportatil')) {
                title = locale == 'it' ? '' : ''

                if (locale == 'it') {
                    title = 'Tulaptopassicurazione'
                }

                if (locale == 'pt') {
                    title = 'seuSegurolaptop'
                }

                if (locale == 'mx') {
                    title = 'SeguroLaptop'
                }
            } else if (this.isCurrentLanding('insurama')) {
                title = 'Insurama'
            } else {
                title = ""
            }

            window.document.title = title
        },
        isCurrentLanding(insurance) {
            return insurance === this.currentLanding
        }
    }
}
</script>

<style>
.loading-overlay {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>