<template>
<div class="multidevice-resume">
    <BoxIcon imgUrl="img/multidispositivo.svg" :title="$t('order.devicePriceSelector.device_label_movil')+' 1'" :class="isValidSelectedDeviceClass(this.devices, risks[0].device, this.incorrectDevice0, step1BtnClicked)">
        <autocomplete required @change="getDevice" :search="searchDevice" :placeholder="$t('order.devicePriceSelector.select_device')" 
            :aria-label="$t('order.devicePriceSelector.select_device')" :get-result-value="getResultValue" 
           @submit="getDevice" @blur="getDevice"  base-class="autocomplete" ref="autocompleteElem1">
        </autocomplete>
    </BoxIcon>
    <BoxIcon imgUrl="img/contracting/precio.svg" :title="$t('contractingLanding.phone_price')+' 1'" :class="isValidLimitedPriceMultideviceClass(minPurchasePriceLimit, maxPurchasePriceLimit,getSumDevicePrices(),risks[0].purchase_price, stepBtnClicked)">
        <input
        v-model="risks[0].purchase_price"
        type="number"
        onkeydown="return event.keyCode !== 69"
        required
        placeholder="p.e. 399"
        @change="updateRisks()"
        @blur="pushMultidispositivoPriceGTM(0)"
        >
    </BoxIcon>
      <div v-if="!multiDeviceCollapsed" class="mt-2">
        <BoxIcon imgUrl="img/multidispositivo.svg" :title="$t('order.devicePriceSelector.device_label_movil')+' 2'" :class="isValidSelectedDeviceClass(this.devices, risks[1].device, this.incorrectDevice1, step1BtnClicked)">
        <autocomplete required @change="getDevice" :search="searchDevice" :placeholder="$t('order.devicePriceSelector.select_device')" 
            :aria-label="$t('order.devicePriceSelector.select_device')" :get-result-value="getResultValue" 
            @submit="getDevice" @blur="getDevice"  base-class="autocomplete" ref="autocompleteElem2">
        </autocomplete>
        </BoxIcon>
        <BoxIcon imgUrl="img/contracting/precio.svg" :title="$t('contractingLanding.phone_price')+' 2'" :class="isValidLimitedPriceMultideviceClass(minPurchasePriceLimit, maxPurchasePriceLimit,getSumDevicePrices(), risks[1].purchase_price, stepBtnClicked)">
            <input
            v-model="risks[1].purchase_price"
            type="number"
            onkeydown="return event.keyCode !== 69"
            required 
            placeholder="p.e. 399"
            @change="updateRisks()"
            @blur="pushMultidispositivoPriceGTM(1)"
            >
        </BoxIcon>
    </div>
    <div class="col-12 my-3" @click="setCollapsable()">
        <a href='javascript:void(0)' style='text-decoration:none;' class='orange'><mdi-icon :icon="multiDeviceCollapsed ? 'mdiPlus' : 'mdiMinus'" /> {{$t('contractingLanding.devices') | lowerCase}} </a>
    </div>
  </div>
</template>
<script>
import { getDeviceModels } from '@/api/ApiClient.js'
import Autocomplete from '@trevoreyre/autocomplete-vue'
import BoxIcon from './BoxIcon.vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'

export default {
    name: 'MultideviceDevice',
    components: {
        Autocomplete,
        BoxIcon
    },
    props: {
        minPurchasePriceLimit: Number,
        maxPurchasePriceLimit: Number,
        currentLanding: String,
        stepBtnClicked: {
            type: Boolean,
            default: false
        },
    },
    data() {
      return {
        risks: [
          {
            device: "",
            purchase_price: "",
            model: ""
          }
        ],
        devices: [],
        multiDeviceCollapsed: true,
        incorrectDevice1: false,
        incorrectDevice0: false,
      }
    },
    created(){
        this.getDevices();
    },
    computed: {
        device0: {
            get(){
                return this.risks[0].device;
            },

            set(value){
                this.risks[0].device = value;
                this.device0Validation = this.isValidMultiDevice(this.risks[0]);
            }
        },
        device1: {
            get(){
                return this.risks[1].device;
            },

            set(value){
                this.risks[1].device = value;
                this.device1Validation = this.isValidMultiDevice(this.risks[1]);
            }
        },
    },
    methods: {
    setPolicyModel(){
        this.getDevice()
    },
    getSumDevicePrices() {
        return this.risks.map(item => {
            return item.purchase_price !== '' ? parseFloat(item.purchase_price) : 0}).reduce((prev, curr) => prev + curr, 0
        );
    },
    async getDevices(){
            this.devices = await getDeviceModels()
        },
      setCollapsable() {
            if(!this.multiDeviceCollapsed){
                this.risks.pop();
            }else{
                this.risks.push({
                    device: "",
                    purchase_price: "",
                    model: ""
                });
            }
            this.multiDeviceCollapsed = !this.multiDeviceCollapsed
        },

        isValidMultiDevice(risk) {
            if (!risk.device.name && risk.device.length == 0 && !this.stepBtnClicked) {
                return ''
            }else if(!risk.device.name && risk.device.length == 0 && this.stepBtnClicked) {
                return 'notValid'
            } else if (risk.device.name && risk.device.name.length == 0) {
                return 'notValid'
            } else if (risk.device.name && this.searchDevice(risk.device.name).some((device)=> device.name === risk.device.name) && risk.device.name === risk.model) {
               return 'valid'
            } else {
                return 'notValid'
            }
        },
      searchDevice(textInput) {
            if (!textInput || textInput.length < 1) { return [] }
            if(typeof(textInput) == 'string')
                return this.devices.filter(device => {
                    if(device.name !== null){
                        return device.name.toLowerCase()
                        .includes(textInput.toLowerCase())
                    }
                })
            else
                return this.devices.filter(device => {
                    if(device.name !== null){
                        return device.name.toLowerCase()
                        .includes(textInput.name.toLowerCase())
                    }
                })

        },
        getResultValue(result) {
            return result.name
        },
        pushMultidispositivoPriceGTM(index) {
            if (this.isValidPrice(this.risks[index].purchase_price)) {
                const deviceNumber = index + 1;
                this.pushGTM('funnel', 'precio'+deviceNumber, 'paso1', this.risks[index].purchase_price, "", false)
            }
        },
        updateRisks(){
            this.$emit("update",this.risks);
        },
        getDevice(result) {
            if(this.devices.includes(result)){             
              if(this.$refs.autocompleteElem1 !== undefined && this.$refs.autocompleteElem1.value !== '' && this.$refs.autocompleteElem1.value === result.name){
                  this.risks[0].model = result.name
                  this.risks[0].name = result.name
                  this.device0Validation = this.isValidMultiDevice(this.risks[0]);
                  this.device0 = result;
                  this.incorrectDevice0 = false;
                  this.pushGTM('funnel', 'marca_movil1', 'paso1', this.risks[0].device.name, "", false);
              }
              if(this.$refs.autocompleteElem2 !== undefined && this.$refs.autocompleteElem2.value !== '' && this.$refs.autocompleteElem2.value === result.name){
                  this.risks[1].model = result.name
                  this.risks[1].name = result.name
                  this.device0Validation = this.isValidMultiDevice(this.risks[1]);
                  this.device1 = result;
                  this.incorrectDevice1 = false;
                  this.pushGTM('funnel', 'marca_movil2', 'paso1', this.risks[1].device.name, "", false);
              }
            }else { 
              if(this.$refs.autocompleteElem1 !== undefined && this.$refs.autocompleteElem1.value !== '' && !this.devices.some(device => device.name === this.$refs.autocompleteElem1.value)){
                  this.device0 = this.$refs.autocompleteElem1.value;
                  this.incorrectDevice0 = true;
              }else if(this.$refs.autocompleteElem1 !== undefined && this.$refs.autocompleteElem1.value === ''){
                  this.device0 = '';
                  this.incorrectDevice0 = false;
              }

              if(this.$refs.autocompleteElem2 !== undefined && this.$refs.autocompleteElem2.value !== '' && !this.devices.some(device => device.name === this.$refs.autocompleteElem2.value)){
                  this.device1 = this.$refs.autocompleteElem2.value;
                  this.incorrectDevice1 = true;
              }else if(this.$refs.autocompleteElem2 !== undefined && this.$refs.autocompleteElem2.value === ''){
                  this.device1 = '';
                  this.incorrectDevice1 = false;
              }
            }
            this.updateRisks();
        },     
    }
}

</script>
<style lang="scss" scoped>
.multidevice-resume{
  text-align: start;
}
</style>