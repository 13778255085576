<template>
    <div>
        <div class="row" @change="$emit('update',$event.target.value)">
            <div class="col-sm-6 d-flex align-items-center">
                <label :for="checkName+1" class="checkbox">{{labelName1}}
                    <input
                        type="checkbox"
                        :name="checkName"
                        :value="checkValue1"
                        :id="checkName+1"
                        :checked="selectedValue===checkValue1"
                        @click="selectValue"
                    >
                      
                    <span class="checkmark"></span>
                </label>
                <div v-if="help1" class="question ml-2">
                    <b-button style="background-color: transparent" v-b-modal.modal-2><img class="help-btn p-2" src="../../assets/palacio-hierro/interrogacion.svg"></b-button>
                </div>
            </div>
            <div class="col-sm-6 d-flex align-items-center">
                <label :for="checkName+2" class="checkbox">{{labelName2}}
                    <input
                        type="checkbox"
                        :name="checkName"
                        :value="checkValue2"
                        :id="checkName+2"
                        :checked="selectedValue===checkValue2"
                        @click="selectValue"
                        >
                    <span class="checkmark"></span>
                </label>
                <div v-if="help2" class="question ml-2">
                    <b-button style="background-color: transparent" v-b-modal.modal-2><img class="help-btn p-2" src="../../assets/palacio-hierro/interrogacion.svg"></b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: "CheckFields",
  props: {
      checkName: String,  
      checkValue1: String,
      checkValue2: String,
      help1: Boolean,
      help2: Boolean,
      labelName1: String,
      labelName2: String,
      selectedValue: String
  },
  methods: {
    selectValue(event){
        if (event.target.value !== this.selectedValue) {
            this.$emit('changedValue', event.target.value);
        } else {
            event.preventDefault();
        }
    },
  }
};
</script>

<style scoped>
label {
    font-family: Gotham-Bold;
    color: #636466;
    font-size: .8rem;
    margin-bottom: 0;
}
</style>