const analyticsIds = {}

if (process.env.VUE_APP_COUNTRY_CODE == 'it') {
    analyticsIds['cookieBotID'] = '8195d4b6-1b59-4759-a2e0-b37f719751b2'
} else if (process.env.VUE_APP_COUNTRY_CODE == 'pt') {
    analyticsIds['cookieBotID'] = '5a9cbc66-1756-4d00-87e2-0e9d8b00366f'

} else {
    analyticsIds['cookieBotID'] = '5acc755e-82a3-4fb9-9077-9d5c7043bf00'
}

module.exports = analyticsIds