<template>
    <div class="sb-input-slim">
      <label>{{title | capitalize}}</label>
      <slot></slot>
    </div>
</template>
<script>
export default {
  name: "Box",
  props: {
      title: String,
  },
  data() {
    return {
      focused: false,
    }
  }
};
</script>

<style lang="scss" scoped>

.sb-input-slim {
  text-align: left;

  label {
    margin-bottom: 2px;
    font-size: 0.8rem;
    font-weight: 600;
    color: #495371;
  }

  input {
      border-radius: 1000px;
      border: 1px solid #FF4C00;
      padding: 12px 24px;
      outline: none;
      box-shadow: none !important;
  }

  &:focus-within {

    label {
      color: #FF4C00;
    }

    input, .b-form-btn-label-control.form-control {
        border: 2px solid #FF4C00 !important;
    }
  }
}
</style>