<template>
    <div class="mt-2 center insu-container">
        <span v-if="deferredPaymentMonths > 0" class="fs-16 primary-colour">
            {{$t("contractingLanding.months_of_deferred_payment")}} <strong>{{ deferredPaymentMonths }} </strong>
            <span v-if="deferredPaymentMonths === 1">{{$t("contractingLanding.month")}}</span>
            <span v-else>{{$t("contractingLanding.months")}}</span>
        </span>
        <br />
        <span class="insu-title">{{title}}</span>
        <br>
        <span class="fs-20 price-landing">{{price}}</span>
        <span class="fs-16 price-landing"> {{currency}}  </span>
        <span class="fs-16"> / </span>
        <template v-if="periodicity == 1">
            <span  class="fs-20">{{ monthsWithoutInterest }} {{$t("contractingLanding.interest_free_months")}} </span>
            <br>
            <span class="fs-20">({{anualPrice}} $ {{$t("contractingLanding.year")}})</span>
        </template>
        <template v-else>
            <span class="fs-20">{{$t("contractingLanding.year")}}</span>
        </template>
    </div>
</template>

<script>

export default {
    name: 'InsurancePrice',
    props: {
        title: String,
        price: Number,
        anualPrice: Number,
        currency: String,
        periodicity: String,
        monthsWithoutInterest: Number,
        deferredPaymentMonths: Number
    }
}

</script>
<style lang="scss" scoped>

    .insu-container{
        margin-bottom: 15px;
    }

    .center{
        text-align: center;
    }
    .insu-title{
        font-size: 18px; 
    }

    .fs-20{
        font-size: 20px; 
    }

    .fs-16{
        font-size: 16px; 
    }
    .price-landing {
        font-weight: bold;
        color: black !important;
    }

    .primary-colour{
        color: var(--primary);
    }
</style>