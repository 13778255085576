<template>
    <div>
      <div class="row">
      <div class="col-12">
          <p class="mb-3 text-center">Captura o escanea el número de ticket de compra de tu cliente</p>
      </div>
    </div>
    <div class="row justify-content-center ml-4">
        <div class="col-9 col-sm-4 ml-4 pl-4 pr-0">
          <input
              class="w-100"
              v-model="code"
              type="number"
              onkeydown="return event.keyCode !== 69"
              @keyup.enter="codeValidation"
              @blur="codeValidation"
              :class="validCode"
              onkeypress="return this.value.length < 18"
          />
            <span class="input-help-error" v-if="messageErrorCodeLength">El código indicado no es correcto. Por favor, introduzca un número de ticket de 18 dígitos.</span>
            <span class="input-help-error" v-if="messageErrorCodeUsed">El código indicado ya ha sido utilizado. Para continuar introduzca uno nuevo.</span>
            <div>
                <img class="camera" src="../../assets/palacio-hierro/camara.svg" alt="">
                <span class="camera-text pl-2">Captura el código a través de una captura</span>
            </div>
          </div>
          <div class="col-3 col-sm-1 px-0">
            <b-button style="background-color: transparent" v-b-modal.modal-1><img class="help-btn p-2" src="../../assets/palacio-hierro/interrogacion.svg"></b-button>
          </div>
        </div>
    </div>
</template>

<script>
import { validateTicketPH } from '@/api/ApiClient.js'

export default {
  name: "ScanCode",
  props: {
    reset: Boolean,
    validationCode: Boolean
  },
  data() {
    return {
      code: '',
      validCode: '',
      messageErrorCodeLength: false,
      messageErrorCodeUsed: false
    }
  },
  methods: {
    async codeValidation(){
      this.messageErrorCodeLength = false;
      this.messageErrorCodeUsed = false;
      if(this.code.length < 18 ){
        this.validCode = 'notVal'
        this.messageErrorCodeLength = true;
        this.$emit('codeValidated', null);
        return;
      } 
      
      const used = await validateTicketPH(this.code)
      if(used) this.messageErrorCodeUsed = true;
      this.validCode = !used ? 'val' : 'notVal'
      if (!used) {
        this.$emit('codeValidated', this.code)
      }
    }
  },
  watch: {
    reset:{
      handler(newValue){
        if(newValue){
          this.code = ''
          this.validCode = ''
        } 
      },
      deep: true
    } ,
    validationCode: {
      handler(newValue){
        if(!newValue){
          this.validCode = 'notVal'
        } 
      },
      deep: true
    }
  }
};
</script>

<style scoped>
  .title{
    font-family: Austin-Medium;
    font-size: 1.5rem;
  }

  .camera-text{
    font-size: 10px;
  }

  input{
      outline: 0;
      border-width: 0 0 2px;
      border-color: black;
      font-style: italic;
      font-weight: bold;
      letter-spacing: 3px;
      text-align: center;
    }
  .help-btn{
    border-radius: 50%;
    box-shadow: 0px 2px 5px 0px grey;;
  }
  .camera {
    width: 30px;
  }
  input.val{
    border-color: green;
    border-width: 0 0 2px;
  }
  input.notVal{
    border-color: red;
    border-width: 0 0 2px;
  }
  .input-help-error {
    color: red;
    font-size: 0.8rem;
  }
</style>

