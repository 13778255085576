import Vue from 'vue'
import landing from '@/config/constants/landing.js'
import i18n from '@/i18n.js'
import { module97, getnumIBAN, isItWorkingHours } from '@/utils/validation.js'
import contactInfo from '@/config/constants/contactInfo';
import dayjs from "dayjs";

Vue.mixin({
    methods: {
        isValidTextField(name, length = 0) {
            if(!name || name.length === 0) return false;
            var nameNoSpaces = name.trim()
            if (nameNoSpaces != "" && nameNoSpaces.length >= length)
                return true
            else
                return false
        },
        isValidNumberField(number, length = 0) {
            if(!number) return false;
            var numberRegex = /^[0-9]+$/;
            var numberNoSpaces = number.trim()
  
            if (numberNoSpaces != "" && numberNoSpaces.length >= length && number.match(numberRegex))
                return true
            else
                return false
        },
        isValidEmail(email) {
            var emailRegex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            if (!email.match(emailRegex))
                return false
            else
                return true
        },
        isValidPrice(price) {
            if (price != "") {
                var priceFloat = parseFloat(price)
                if (priceFloat <= 0) {
                    return false
                } else {
                    return true
                }
            } else {
                return false
            }
        },
        isValidLimitedPrice(min, max, price) {
            if (price != "") {
                var priceFloat = parseFloat(price)
                return priceFloat >= min && priceFloat <= max;
            } else {
                return false;
            }
        },
        areValidRisks(risks) {
            var valid = true
            risks.forEach((risk) => {
                if (valid == true) {
                    if (risk.device === '' || !this.isValidPrice(risk.purchase_price)) {
                        valid = false;
                    }
                }
            })
            return valid
        },
        isValidPurchaseDate(currentLanding, date) {
            if(!date) return false;
            if(dayjs(date).isAfter(dayjs())) return false;

            var maxDifferenceOfDays = 0
            switch (currentLanding) {
                case landing.seguropatinete.name:
                    maxDifferenceOfDays = 365
                    break
                case landing.seguroportatil.name:
                    maxDifferenceOfDays = 1080
                    break
            }
            var today = new Date(dayjs().format("YYYY-MM-DD"));
            var dateMillis = dayjs(date);
            var todayMillis = dayjs(today);
            var calculatedDiff = todayMillis.diff(dateMillis, "day");

            return maxDifferenceOfDays == 0 || calculatedDiff < maxDifferenceOfDays;
        },
        isValidBirthDate(date) {
            var dateRegex = /^([0-2][0-9]|3[0-1])(\/)(0[1-9]|1[0-2])\2(\d{4})$/;
            if (!date.match(dateRegex)) {
                return false
            }

            var dateParts = date.split("/");
            var d = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);

            if (Object.prototype.toString.call(d) === "[object Date]") {
                if (isNaN(d.getFullYear()) || isNaN(d.getDay()) || isNaN(d.getMonth())) {
                    return false

                } else {

                    if (parseInt(d.getFullYear()) < 1920) {
                        return false
                    }

                    var minDifferenceOfDays = 6570; //18 años solo mayores de edad
                    var today = new Date(dayjs().format("YYYY-MM-DD"));
                    var dateMillis = dayjs(d);
                    var todayMillis = dayjs(today);
                    var calculatedDiff = todayMillis.diff(dateMillis, "day");
                    if (calculatedDiff >= minDifferenceOfDays) {
                        return true

                    } else {
                        return false
                    }
                }
            } else {
                return false
            }
        },
        validateMonthAndDay(date){
            var dateParts = date.split("/");
            if(dateParts[1] < 1 || dateParts[1] > 12){
                return 'invalidMonth'
            }
            else if(dateParts[0] < 1 || dateParts[0] > new Date(dateParts[2], dateParts[1], 0).getDate() ){
                return 'invalidDay'
            }
        },
        isValidPersonAge(date) {
            var dateParts = date.split("/");
            var d = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);

            if (Object.prototype.toString.call(d) === "[object Date]") {
                if (isNaN(d.getFullYear()) || isNaN(d.getDay()) || isNaN(d.getMonth())) {
                    return 'invalidFormat'

                } else {

                    var minDifferenceOfDays = 6570; //18 años solo mayores de edad
                    var today = new Date(dayjs().format("YYYY-MM-DD"));
                    var dateMillis = dayjs(d);
                    var todayMillis = dayjs(today);
                    var calculatedDiff = todayMillis.diff(dateMillis, "day");
                    if (calculatedDiff >= minDifferenceOfDays) {
                        return 'validAge'

                    } else {
                        return 'invalidAge'
                    }
                }
            } else {
                return false
            }
        },
        isValidConstitutionDate(date) {
            var dateRegex = /^([0-2][0-9]|3[0-1])(\/)(0[1-9]|1[0-2])\2(\d{4})$/;
            if (!date.match(dateRegex)) {
                return false
            }
            var dateParts = date.split("/");
            var d = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);

            if (Object.prototype.toString.call(d) === "[object Date]") {
                if (isNaN(d.getFullYear()) || isNaN(d.getDay()) || isNaN(d.getMonth())) {
                    return false

                } else {
                    if (parseInt(d.getFullYear()) < 1920) {
                        return false
                    }
                    var minDifferenceOfDays = 0; //Creada desde hace mas de 1 dia
                    var today = new Date(dayjs().format("YYYY-MM-DD"));
                    var dateMillis = dayjs(d);
                    var todayMillis = dayjs(today);
                    var calculatedDiff = todayMillis.diff(dateMillis, "day");
                    if (calculatedDiff + 1 > minDifferenceOfDays) {
                        return true

                    } else {
                        return false
                    }
                }
            } else {
                return false
            }
        },
        isValidPhone(phone, length = 9) {
            var phoneRegex = /^[+]?[0-9]+$/;

            if (process.env.VUE_APP_COUNTRY_CODE == 'it') {
                length = 10
            }

            if (process.env.VUE_APP_COUNTRY_CODE == 'pt') {
                length = 9
            }

            if (process.env.VUE_APP_COUNTRY_CODE == 'mx') {
                length = 10
            }

            phone = phone.replace(/\s/g, '');

            phone = phone.replace(contactInfo.prefix,'');

            if (phone == "" || phone.length <= length - 1 || !phone.match(phoneRegex) || phone.length !== length )
                return false
            else
                return true
        },
        isValidNifNie(nif, date = '') {
            var temp = nif.toUpperCase();
            if (process.env.VUE_APP_COUNTRY_CODE == 'it') {
                return this.isValidTextField(nif, 1)
            } else if (process.env.VUE_APP_COUNTRY_CODE == 'mx') {
                if (nif.length != 13) {
                    return false;
                }

                if (!/^[A-Z]{4}\d{6}[A-Z0-9]{3}$/.test(temp)) {
                    return false;
                }

                if (date != '') {
                    const year = date.substring(8, 10)
                    const month = date.substring(3, 5)
                    const day = date.substring(0, 2)
                    const number = year+month+day
                    if (temp.substring(4, 10) != number) {
                        return false;
                    }
                }

                return true;
            } else {

                var dniString = "TRWAGMYFPDXBNJZSQVHLCKE";
                if (temp != '') {

                    if (!/^[XYZ]?\d{5,8}[A-Z]$/.test(temp)) {
                        return false;
                    }

                    if (/^[0-9]{8}[A-Z]{1}$/.test(temp)) {
                        var position = nif.substring(8, 0) % 23;
                        var letter = dniString.charAt(position);
                        var letterdni = temp.charAt(8);
                        if (letter == letterdni) {
                            return true;
                        } else {
                            return false;
                        }
                    }

                    if (/^[XYZ]{1}/.test(temp)) {
                        temp = temp.replace('X', '0')
                        temp = temp.replace('Y', '1')
                        temp = temp.replace('Z', '2')
                        var pos = temp.substring(0, 8) % 23;
                        if (nif.toUpperCase().charAt(8) == dniString.substring(pos, pos + 1)) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                }
                return false;
            }
        },
        isValidImeiNumber(imei) {
            var imeiRegex = /^\d{15}$/;
            return imeiRegex.test(imei) && this.isValidImei(imei);
        },
        isValidCardNumber(cardNumber) {
            if (/^[0-9]{16}$/i.test(cardNumber)) {
                return true
            } else {
                return false
            }
        },
        isValidExpirationDateMonthCard(month) {
            if (month >= 1 && month <= 12) {
                return true
            } else {
                return false
            }
        },
        isValidExpirationDateYearCard(year) {
            if (year >= 21 && year <= 50) {
                return true
            } else {
                return false
            }
        },
        isValidCVVCard(cvv) {
            if (cvv.length == 3) {
                return true
            } else {
                return false
            }
        },
        isValidIBAN(iban) {
            if (process.env.VUE_APP_COUNTRY_CODE == 'it') {
                //return this.isValidTextField(iban, 27)
                return this.isValidTextField(iban, 1)
            } else {
                var ibanTmp = iban.toUpperCase();

                ibanTmp = ibanTmp.trim();
                ibanTmp = ibanTmp.replace(/\s/g, "");

                var letter1, letter2, num1, num2;
                var isbanaux;

                if (ibanTmp.length != 24) {
                    return false;
                }

                letter1 = ibanTmp.substring(0, 1);
                letter2 = ibanTmp.substring(1, 2);
                num1 = getnumIBAN(letter1);
                num2 = getnumIBAN(letter2);

                isbanaux = String(num1) + String(num2) + ibanTmp.substring(2);
                isbanaux = isbanaux.substring(6) + isbanaux.substring(0, 6);

                var remainer = module97(isbanaux);
                if (remainer == 1) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        isValidCard(userData) {

            if (userData.card_expiration_month.length == 0 || userData.card_expiration_year.length == 0) {
                return {
                    "valid": false,
                    "message": i18n.t('validation.error_empty_date'),
                    "ibanRequired": false
                }
            }

            return {
                "valid": true,
                "message": "",
                "ibanRequired": false
            }
        },
        isValidImei(imei){
            let odd = false;
            let sum = 0;
            var arr = imei.toString(10).split('').map(Number);
            var arrReverse = arr.reverse();
            arrReverse.forEach(e => {
                let numb;
                sum += (numb = (odd ? e*2 : e) ) > 9 ? numb.toString(10).split('').map(Number).reduce((prev, actual) => prev + actual, 0) : numb
                odd = !odd;
            });
            return ((sum % 10 === 0) && (sum !== 0))
        },
        monthsRemainingExpirationCard(month, year) {
            if (month.length < 2 && month > 0) {
                month = "0" + month;
            }

            if (year.length < 2 && year > 0) {
                year = "0" + year;
            }
            var expiration = "20" + year + month
            var aux = dayjs(expiration + "01").format("YYYYMM");
            var now = dayjs()
                .date(1)
                .format("YYYYMM");
            var monthsRemaining = dayjs(aux + "01").diff(now + "01", "month");

            return (monthsRemaining);
        },
        isRequiredIBAN(month, year) {
            if (month.length < 2 && month > 0) {
                month = "0" + month;
            }

            if (year.length < 2 && year > 0) {
                year = "0" + year;
            }
            var expiration = "20" + year + month
            var aux = dayjs(expiration + "01").format("YYYYMM");
            var now = dayjs()
                .date(1)
                .format("YYYYMM");
            var monthsRemaining = dayjs(aux + "01").diff(now + "01", "month");

            return (monthsRemaining);
        },
        isHoliday() {
            var holidays = contactInfo.holidays;
            var itIsWorkingHours = isItWorkingHours();
            if (!itIsWorkingHours) {
                return true;
            }
            var d = new Date();
            var today = d.getDate() + '-' + (d.getMonth() + 1);

            return holidays.some(function(holiday) {
                return holiday === today;
            });
        },
        isValidOrderStep1(currentLanding, policyData) {
            // Multidispositivo
            if (currentLanding == landing.seguromultidispositivo.name)
                return this.isValidPhone(policyData.phone) && this.areValidRisks(policyData.risks);

            // Precio de compra y teléfono (campos generales que comparten móvil, patinete, portátil y extensión de garantía)
            if(!this.isValidPrice(policyData.purchasePrice) || !this.isValidPhone(policyData.phone))
                return false;
            
            // Patinete, portátil y extensión de garantía
            if (currentLanding == landing.seguropatinete.name || currentLanding == landing.seguroportatil.name || currentLanding == landing.extensiondegarantia.name) {
                if (!this.isValidTextField(policyData.reference, 1) || !this.isValidTextField(policyData.model, 4) || !this.isValidPurchaseDate(currentLanding, policyData.purchaseDate)) return false;

                return currentLanding == landing.extensiondegarantia.name? this.isValidTextField(policyData.device_type, 1) : true;
            }
            
            // Móvil
            if (policyData.device != "") {
                return currentLanding == landing.seguromovilrobo.name ? this.isValidImeiNumber(policyData.imei) : true;
            }
        },
        isValidOrderStep2(userData) {

            if (process.env.VUE_APP_COUNTRY_CODE == 'it') {
                if (userData.acceptance_coverages <= 0) {
                    return false;
                }
            }

            if (process.env.VUE_APP_COUNTRY_CODE == 'mx') {
                if( 
                    !this.isValidTextField(userData.state, 3) ||
                    !this.isValidTextField(userData.town, 3) ||
                    !this.isValidTextField(userData.street, 3) ||
                    !this.isValidTextField(userData.colony, 3) ||
                    !this.isValidBirthDate(userData.birth_date) ||
                    !this.isValidNumberField(userData.postal_code, 4) ||
                    !this.isValidNumberField(userData.exterior_number, 1) ||
                    userData.acceptance_type!==true ||
                    userData.acceptance_type_2!==true
                ) {
                    return false;
                }
            }

            return this.isValidNifNie(userData.nif) &&
                this.isValidTextField(userData.name) &&
                this.isValidEmail(userData.email);
        },

        isValidSecondStepIronPalace(userData, personType){
            if( 
                !this.isValidTextField(userData.street, 3) ||
                !this.isValidTextField(userData.colony, 3) ||
                !this.isValidNumberField(userData.postal_code, 4) ||
                !this.isValidNumberField(userData.exterior_number, 1) ||
                !this.isValidPhone(userData.phone) ||
                !this.isValidNumberField(userData.regimen_fiscal_code,3)
                ||
                !this.userData.acceptance_type ||
                !this.userData.acceptance_type_2
            ) {
                return false;
            }
            return (personType === 'individualPerson' ? this.isValidNifNie(userData.nif) && 
            this.isValidTextField(userData.surname1,4) && this.isValidTextField(userData.surname2,4) && this.isValidBirthDate(userData.birth_date) 
            : this.isValidMoralPerson(userData.nif)) && this.isValidTextField(userData.name,4)  && this.isValidEmail(userData.email) && this.isValidConstitutionDate(userData.birth_date);
        },
        isValidMoralPerson(nif) {
            const temp = nif.toUpperCase();
            if (process.env.VUE_APP_COUNTRY_CODE == 'mx') {
                if (nif.length != 12) {
                    return false;
                }
                if (!/^[A-Z]{3}\d{6}[0-9A-Z]{3}$/.test(temp)) {
                    return false;
                }
                return true;
            }
            return false;
        },
    }
})