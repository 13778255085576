<template>
  <div class="palacio-hierro">
    <Modals
    :show_help="show_help"
    :rates="rates"
    />
    <template v-if="!isLoading">
      <template v-if="!validIp">
        <header>
          <div class="logo mb-3">
          <img src="../../assets/palacio-hierro/Logo_principal.svg">
        </div>
        </header>
        <div class="row justify-content-center mt-4"><p>Esta página web no está disponible</p></div>
        <div class="row justify-content-center mt-1"><p>Para más información contacte con: <span>clientes@insurama.mx</span> </p></div>
      </template>
      <template v-else-if="!actived">
        <p>{{$t(error)}}</p>
      </template>
      <template v-else>
        <header>
          <div class="logo mb-3">
          <img @click="goToFirstStep" src="../../assets/palacio-hierro/Logo_principal.svg">
          </div>
        </header>
        <keep-alive>
          <component
        :is="stepFormComponent"
        :reset="reset"
        :step1Data="step1Data"
        :policyNumber="policyNumber"
        :policyEmail="policyEmail"
        :userName="userName"
        @showHelp="showHelp"
        @reset="resetData"
        @nextStep="step++"
        @error="handleError"
        @onSelectedProductAndRange="onSelectedProductAndRange($event)"
        @codeValidated="codeValidated"
        @storeSelected="storeSelected"
        @policyCreated="policyCreated"
        @returnStepOne="step=1"
        >
          </component>
        </keep-alive>
      </template>
    </template> 
    <template v-else>
      <div class="loading-overlay" key="loading">
        <img src='@/assets/loader.gif' height=64>
      </div>
    </template>
  </div>
</template>

<script>
import FirstStepIronPalace from "./FirstStepIronPalace.vue";
import SecondStepIronPalace from "./SecondStepIronPalace.vue";
import ThirdStepIronPalace from "./ThirdStepIronPalace.vue";
import Modals from "../../components/ironPalace/Modals.vue";
import axios from 'axios';
import { checkIpIronPalace } from '@/api/ApiClient.js'

export default {
  name: "ContractingPalace",
  components: {
    FirstStepIronPalace,
    SecondStepIronPalace,
    ThirdStepIronPalace,
    Modals
  },
  props: {},
  data() {
    return {
      step: 1,
      actived: true,
      error: "",
      reset: false,
      step1Data: {},
      policyNumber: '',
      show_help: [],
      rates: [],
      validIp: false,
      isLoading: true
    };
  },
  created() {
    this.validateIp();
    this.setMetadata();
  },
  computed: {
    stepFormComponent: function () {
      switch (this.step) {
        case 1:
          return FirstStepIronPalace;
        case 2:
          return SecondStepIronPalace;
        case 3:
          return ThirdStepIronPalace;
        default:
          this.handleError();
          return "";
      }
    },
  },
  methods: {
    setMetadata() {
      document.title = "Palacio de Hierro";
      var favicon = document.querySelector("link[rel~='icon']");
      favicon.href = require(`@/assets/palacio-hierro/favicon-ph.png`)
    },
    resetData(reset) {
      this.reset = reset;
      this.step = 1;
    },
    handleError() {
      this.actived = false;
      this.error = "Mensaje de Error";
    },

    onSelectedProductAndRange($event) {
      this.step1Data = {
        ...this.step1Data,
        ...$event
      };
    },
    codeValidated(code) {
      this.step1Data = {
      ...this.step1Data,
      ticketCode: code
      };
    },
    showHelp(show_help, rates){
      this.show_help = show_help
      this.rates = rates
    },
    storeSelected(storeId) {
      this.step1Data = {
      ...this.step1Data,
      storeId: storeId
      };
    },
    policyCreated(policyNumber, policyEmail, userName) {
      this.policyNumber = policyNumber;
      this.policyEmail = policyEmail;
      this.userName = userName;
    },
    goToFirstStep(){
      window.location.reload();
    },
    async validateIp(){
      
      const response = await axios.get('https://api.ipify.org/?format=json');
      try {
          const data = await checkIpIronPalace (response?.data?.ip) ;
          if (data.ip_access) this.validIp = true;
      }
      catch{
        this.handleError();
      }
      finally{
        this.isLoading = false;
      }
    }
  },
  watch: {
    step: {
      handler(){
        window.scrollTo(0,0)
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.logo {
  padding-top: 20px;
  padding-left: 50px;
  padding-bottom: 15px;
  background-color: #fec72a;
}
p {
    font-family: "Gotham-Light";
  }
span {
    font-family: "Gotham-Bold";
}
.loading-overlay {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>

<style lang="scss">
.palacio-hierro {
  font-family: Gotham-Light;

  h1,
  h2 {
    font-family: "Gotham-Bold";
  }
  .btn-secondary {
    border-color: transparent !important;
    &:focus {
      border-color: transparent !important;
      box-shadow: none !important;
    }
  }
  header {
    position: sticky;
    top: 0;
    z-index: 1040;

  }
  .checkbox {
    display: block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    user-select: none;
  }

  .checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px solid #636466;
    border-radius: 4px;
  }

  .checkbox .checkmark.val {
    border-color: green;
    border-width: 2px;
  }

  .checkbox .checkmark.notVal {
    border-color: red;
    border-width: 2px;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .checkbox input:checked ~ .checkmark:after {
    display: block;
  }

  .checkbox .checkmark:after {
    left: 4px;
    top: 0px;
    width: 8px;
    height: 12px;
    border: solid #45b549;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .radio {
    display: block;
    cursor: pointer;
    user-select: none;
    text-align: left;
    & + .radio {
      margin-top: 12px;
    }
    input {
      display: none;
      & + span {
        display: inline-block;
        position: relative;
        padding-left: 30px;
        &:before {
          content: "";
          display: block;
          position: absolute;
          top: 0px;
          left: 0px;
          border-radius: 50%;
          margin-right: 5px;
          width: 16px;
          height: 16px;
          border: 2px solid #636466;
          background: #fff;
        }
        &:after {
          content: "";
          display: block;
          width: 12px;
          height: 12px;
          background: #fec72a;
          position: absolute;
          border-radius: 50%;
          top: 2px;
          left: 2px;
          opacity: 0;
          transform: scale(0, 0);
          transition: all 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
        }
      }
      &:checked + span:after {
        opacity: 1;
        transform: scale(1, 1);
      }
    }
  }

  .logo img{
    cursor: pointer;
  }
}

@font-face {
  font-family: Austin;
  src: url("../../assets/fonts-ph/Austin-Italic.otf"),
    url("../../assets/fonts-ph/Austin-Bold.otf"),
    url("../../assets/fonts-ph/Austin-Roman.otf"),
    url("../../assets/fonts-ph/Austin-MediumItalic.otf"),
    url("../../assets/fonts-ph/Austin-Light.otf"),
    url("../../assets/fonts-ph/Austin-Medium.otf");
}
@font-face {
  font-family: NeutraText;
  src: url("../../assets/fonts-ph/NeutraText-Demi.otf"),
    url("../../assets/fonts-ph/NeutraTextBold.otf"),
    url("../../assets/fonts-ph/NeutraTextBoldAlt.otf"),
    url("../../assets/fonts-ph/NeutraTextBook.otf"),
    url("../../assets/fonts-ph/NeutraTextBookAlt.otf"),
    url("../../assets/fonts-ph/NeutraTextDemiAlt.otf"),
    url("../../assets/fonts-ph/NeutraTextLightAlt.otf");
}
@font-face {
  font-family: Gotham-Bold;
  src: url("../../assets/fonts-ph/Gotham-Bold.otf");
}
@font-face {
  font-family: Gotham-Light;
  src: url("../../assets/fonts-ph/Gotham-Light.otf");
}
@font-face {
  font-family: Gotham-Book;
  src: url("../../assets/fonts-ph/Gotham-Book.otf");
}
@font-face {
  font-family: Vonnes;
  src: url("../../assets/fonts-ph/Vonnes-BlackComp.otf"),
    url("../../assets/fonts-ph/Vonnes-Bold.otf"),
    url("../../assets/fonts-ph/Vonnes-BoldCond.otf"),
    url("../../assets/fonts-ph/Vonnes-BoldCondItalic.otf"),
    url("../../assets/fonts-ph/Vonnes-Book.otf"),
    url("../../assets/fonts-ph/Vonnes-BookCond.otf"),
    url("../../assets/fonts-ph/Vonnes-Light.otf"),
    url("../../assets/fonts-ph/Vonnes-LightCond.otf"),
    url("../../assets/fonts-ph/Vonnes-LightCondItalic.otf"),
    url("../../assets/fonts-ph/Vonnes-MediumComp.otf"),
    url("../../assets/fonts-ph/Vonnes-MediumCompItalic.otf");
}
</style>
