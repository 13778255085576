import Vue from 'vue'
import subdomain from '@/config/constants/subdomain.js'
import landing from '@/config/constants/landing.js'

import { getAllProducts } from '@/api/ApiClient';

const state = Vue.observable({
    currentLanding: "",
    products: []
})

export const getters = {
    currentLanding: () => state.currentLanding,
    getProducts: () => state.products
}

export const mutations = {
    setCurrentLanding: (newSubdomain) => {
        if (Object.keys(subdomain).includes(newSubdomain)) {
            state.currentLanding = newSubdomain
        } else if (newSubdomain === subdomain.seguromovil) {
            state.currentLanding = landing.seguromovil.name
        }
        
        return state.currentLanding
    },

    setProducts (products) {
        state.products = products
    }
}

export const actions = {
    getProducts: async function () {
        const products = await getAllProducts()
        mutations.setProducts(products)
    }
}