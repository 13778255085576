export default {
    seguromovil: {
        "name": "seguromovil",
        "title": "Seguro Celular",
        "product_type_id": "1"
    },
    seguromovilvoltaje: {
        "name": "seguromovilvoltaje",
        "title": "Seguro Celular Voltaje",
        "product_type_id": "7"
    },
    seguromovilrobo: {
        "name": "seguromovilrobo",
        "title": "Seguro Celular Robo",
        "product_type_id": "11"
    },
    seguropatinete: {
        "name": "seguropatinete",
        "title": "Seguro Patinete",
        "product_type_id": "3"
    },
    seguromultidispositivo: {
        "name": "seguromultidispositivo",
        "title": "Seguro Multidispositivo",
        "product_type_id": "5"
    },
    seguromultidispositivovoltaje: {
        "name": "seguromultidispositivovoltaje",
        "title": "Seguro Multidispositivo Voltaje",
        "product_type_id": "8"
    },
    seguroportatil: {
        "name": "seguroportatil",
        "title": "Seguro Laptop",
        "product_type_id": "6"
    },
    seguroportatilvoltaje: {
        "name": "seguroportatilvoltaje",
        "title": "Seguro Laptop Voltaje",
        "product_type_id": "12"
    },
    seguroportatilrobo: {
        "name": "seguroportatilrobo",
        "title": "Seguro Laptop Robo",
        "product_type_id": "13"
    },
    extensiondegarantia: {
        "name": "extensiondegarantia",
        "title": "Extensión de garantía",
        "product_type_id": "4"
    },
    insurama: {
        "name": "insurama",
        "product_type_id": "0"
    }
    //Tusegurotranquilidad: product_type_id: 2
    //Tuseguromascota: product_type_id: 7
}