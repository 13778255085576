<template>
    <div>
        <form @submit.prevent="saveData">
            <BoxIcon imgUrl="img/contracting/precio.svg" :title="$t('contractingLanding.limit')" :class="isValidPriceClass(policyData.purchasePrice, step1BtnClicked)">
                <select v-model="policyData.purchasePrice" @change="updatePrice">
                    <option v-for="(limit, i) in multideviceLimits" v-bind:key="limit" :value="limit">
                    {{multideviceLimitsText[i]}}
                    </option>
                </select>
             </BoxIcon>
            <MultideviceDevice
            ref="devicesForm"
            :stepBtnClicked="step1BtnClicked"
            :minPurchasePriceLimit="minPurchasePriceLimit"
            :maxPurchasePriceLimit="policyData.purchasePrice"
            :currentLanding="currentLanding"
            @update="updateRisks">
            </MultideviceDevice>
            <Periodicity
                v-if="showPeriodicityOptions"
                :periodicity="policyData.periodicity"
                :showMonthlyPeriodicityOption="showMonthlyPeriodicityOption"
                @update="updatePeriodicity">
            </Periodicity>
            <BoxIcon imgUrl="img/contracting/numero_movil.svg" :title="$t('contractingLanding.phone')" :class="isValidPhoneClass(policyData.phone, step1BtnClicked)">
                <input
                    v-model="policyData.phone"
                    type="number"
                    required
                    onkeypress="return this.value.length < 10;"
                    onkeydown="return event.keyCode !== 69"
                    maxlength="10"
                    minlength="10"
                    :placeholder="$t('contractingLanding.enter_phone')"
                    @blur="isValidPhone(policyData.phone) && pushGTM('funnel', 'telefono', 'paso1', '', productName)"
                />
            </BoxIcon>
            <button type="submit" class="btn btn-block btn-linear btn-siguiente">
                <span>{{$t('contractingLanding.continue')}}</span>
                <img class="ml-2" src="../../assets/img/contracting/next.svg" alt="">
            </button>
        </form>
    </div>
</template>

<script>
import BoxIcon from './BoxIcon.vue';
import Periodicity from './Periodicity.vue';
import MultideviceDevice from './MultideviceDevice.vue';
import landing from '@/config/constants/landing.js';

export default {
    name: 'MultideviceForm',
    components: {
        BoxIcon,
        Periodicity,
        MultideviceDevice
    },
    props: {
        showPeriodicityOptions: Boolean,
        showMonthlyPeriodicityOption: Boolean,
        initialPeriodicity: String,
        productName: String,
        minPurchasePriceLimit: Number,
        multideviceLimits: Array,
        multideviceLimitsText: Array,
        currentLanding: String,
    },
    data(){
        return{
            policyData: {
                risks: [
                    {
                        device: "",
                        purchase_price: ""
                    }
                ],
                phone: '',
                periodicity: '',
                purchasePrice: ""
            },
            step1BtnClicked: false
            }
    },
    created(){
        this.policyData.periodicity = this.initialPeriodicity;
    },
    watch: {
        initialPeriodicity: {
            handler(newValue){
                this.policyData.periodicity = newValue;
            }, deep: true
        }
    },
    methods: {
        updateCapital(capital){
            this.policyData.purchasePrice = capital;
            this.updatePrice();
        },
        updatePeriodicity(periodicity){
            this.policyData.periodicity = periodicity;
            this.updatePrice();
        },
        updateRisks(risks) {
            this.policyData.risks=risks;
            this.updatePrice();
        },
        updatePrice() {
            this.pushGTM('funnel', 'precio', 'paso1', this.policyData.purchasePrice, this.productName, true);
            this.$emit("updatePrice",this.policyData);
        },
        getAmountDevices() {
            return this.policyData.risks.map(item => Number(item.purchase_price)).reduce((prev, curr) => prev + curr, 0);
        },
        saveData(){
            this.step1BtnClicked= true;

            if(this.getAmountDevices() > Number(this.policyData.purchasePrice)) return;

            let validRisks = false;
            if(this.policyData.risks.length===1){
                validRisks = this.$refs.devicesForm.isValidMultiDevice(this.policyData.risks[0])==='valid';
            } else {
                validRisks = this.$refs.devicesForm.isValidMultiDevice(this.policyData.risks[0])==='valid' && this.$refs.devicesForm.isValidMultiDevice(this.policyData.risks[1])==='valid';
            }
            
            if(validRisks && this.isValidOrderStep1(landing.seguromultidispositivo.name, this.policyData))
                this.$emit("save", this.policyData);
        },
    }
}

</script>